import React from 'react'
import { Router, Switch, Redirect } from 'react-router-dom'

import CohortFilter from '../views/CohortFilter'
import FunctionList from '../views/FunctionList'
import PreviewCohort from '../views/PreviewCohort'
import CohortList from '../views/CohortList'
import Login from '../views/Login'

import * as routes from './routes'
import history from './history'
import UnauthorizedRoute from './Routers/UnauthorizedRoute'
import AuthorizedRoute from './Routers/AuthorizedRoute'

export default function AppRouter () {
  return (
    <Router history={history}>
      <Switch>
        <UnauthorizedRoute exact path={routes.LOGIN} component={Login}/>
        <AuthorizedRoute exact path={routes.HOMEPAGE} render={() => <Redirect to={routes.COHORT_LIST}/>}/>
        <AuthorizedRoute exact path={routes.FUNCTION_LIST} component={FunctionList}/>
        <AuthorizedRoute exact path={routes.PREVIEW_COHORT} component={PreviewCohort}/>
        <AuthorizedRoute exact path={routes.COHORT_LIST} component={CohortList}/>
        <AuthorizedRoute exact path={routes.FILTER()} component={CohortFilter}/>
      </Switch>
    </Router>
  )
}
