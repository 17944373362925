import { action } from 'typesafe-actions'
import { LoginI, LoginTypes } from './types'

export const loginLoad = () => action(LoginTypes.LOGIN_LOAD)

export const loginSet = (data: LoginI) => action(LoginTypes.LOGIN_SET, data)

export const loginFailed = (error) => action(LoginTypes.LOGIN_FAILED, error)

export const loginSuccess = () => action(LoginTypes.LOGIN_SUCCESS)
