export enum EAvailablePeriod {
  NIGHTLY = 'NIGHTLY',
  DAILY = 'DAILY',
  ALL = 'ALL',
  MORNING = 'MORNING',
  AFTERNOON = 'AFTERNOON',
  EVENING = 'EVENING',
  NIGHT = 'NIGHT'
}

export enum EAvailablePeriodDescription {
  NIGHTLY = 'Noturno',
  DAILY = 'Diurno',
  ALL = 'Ambos',
  MORNING = 'Manhã',
  AFTERNOON = 'Tarde',
  EVENING = 'Noite',
  NIGHT = 'Madrugada'
}