import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { RouteComponentProps } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { Spinner, StickyToast } from '@mindlab-org/component-library'

import * as routes from '../../routes/routes'
import Header from '../../components/Header'
import FunctionCard from '../../components/FunctionCard'
import { FunctionState } from '../../redux/functions/types'
import { ApplicationState } from '../../redux/store'
import * as FunctionActions from '../../redux/functions/actions'

interface StateProps {
  functions: FunctionState
}

interface DispatchProps {
  functionSet(): void
}

interface OwnProps extends RouteComponentProps {}

type Props = StateProps & DispatchProps & OwnProps

interface State {
  functions: FunctionState
}

class FunctionList extends Component<Props, State> {
  componentDidMount () {
    this.props.functionSet()
  }

  renderFunctionCards = () => {
    const functions = this.props.functions
    return functions.data?.map(
      func => {
        const redirectToFilters = () => this.props.history.push(routes.FILTER(func.id))
        return (
          <FunctionCard
            key={func.id}
            id={func.id}
            onClickCard={() => redirectToFilters()}
            title={func.slug.split('-').join(' ')}
            totalApplications={`${func.totalApplications}`}
          />
        )
      }
    )
  }

  redirectToHome = () => {
    this.props.history.push(routes.HOMEPAGE)
  }

  renderSpinner = () => (
    <Spinner spinnerArea="30hv"/>
  )

  renderViewContent = () => {
    return this.props.functions.isLoading
      ? this.renderSpinner()
      : this.props.functions.success &&
      this.renderFunctionCards()
  }

  render () {
    const functions = this.props.functions

    return (
      <div className="View FunctionList">
        { this.props.functions.error && (
          <div style={{
            display: 'block',
            width: '100%'
          }}>
            <StickyToast show>
              <Typography style={{ color: '#ff4d4d' }}>
                {this.props.functions.error.message}
              </Typography>
            </StickyToast>
          </div>
        )}
        <Header
          onClickBack={() => this.props.history.push(routes.HOMEPAGE)}
          onClickCancel={() => this.redirectToHome()}
          title="Novo Cohort"/>
        <div className="View__Background">
          <div className="View__Content--centered">
            <div className="View__Title">
              <Typography
                variant="h2">
                Escolha uma função
              </Typography>
            </div>
            <div className="View__Subtitle">
              <Typography
                variant="h3">
                {functions.data?.length} funções
              </Typography>
            </div>
            <div className="FunctionList__Functions">
              {this.renderViewContent()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => ({
  functions: state.functions
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => bindActionCreators(
  FunctionActions, dispatch
)

export default connect(mapStateToProps, mapDispatchToProps)(FunctionList)
