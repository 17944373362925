import React from 'react'
import { Typography } from '@material-ui/core'

interface Props {
  onClick?: React.MouseEventHandler,
  texts: string[],
  icon: any
}

const renderItems = (texts: string[], onClick?: any) => {
  return texts.map(
    text => {
      return (
        <div
          className="Label__Content"
          onClick={onClick}
          key={text}>
          <Typography
            variant="h4">
            {text}
          </Typography>
        </div>
      )
    }
  )
}

function Label ({
  onClick,
  texts,
  icon
}: Props) {
  return (

    <div className="Label">
      {icon || ''}
      {renderItems(texts, onClick)}
    </div>
  )
}

export default Label
