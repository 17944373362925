import { CohortFilterI } from '../cohorts/types'

/**
 * ACTION TYPES
 */
export enum FiltersTypes {
  FILTERS_SET_SUCCESS = 'FILTERS_SET_SUCCESS',
  FILTERS_SET_FAILED = 'FILTERS_SET_FAILED',
  FILTER_SET_CLEAN = 'FILTER_SET_CLEAN'
}

/**
 * Data types
 */
export interface FiltersI extends CohortFilterI {}

/**
 * State type
 */
export interface FiltersState {
  data?: FiltersI,
  error: {
    message: string
  } | null,
  isLoading: boolean,
  success: boolean
}
