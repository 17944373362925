import { EAvailablePeriod, EEmploymentCode, EGenderCode, ETransportationCode } from '../../utils/enums'
import { EApplicationStatus } from '../../utils/enums/application-status.enum'
import { EAssessmentStatus } from '../../utils/enums/assessment-status.enum'
import { ECohortStatus } from '../../utils/enums/cohort-status.enum'
import { EDegreeCode } from '../../utils/enums/degree.enum'
import { EMobileOS } from '../../utils/enums/mobile-os.enum'

/**
 * ACTION TYPES
 */
export enum CohortTypes {
  COHORT_GET = 'COHORT_GET',
  COHORT_GET_LOAD = 'COHORT_GET_LOAD',
  COHORT_GET_SUCCESS = 'COHORT_GET_SUCCESS',
  COHORT_GET_FAILED = 'COHORT_GET_FAILED',
  COHORT_GET_CLEAR = 'COHORT_GET_CLEAR'
}

export enum CohortPreviewTypes {
  COHORT_PREVIEW_GET = 'COHORT_PREVIEW_GET',
  COHORT_PREVIEW_GET_LOAD = 'COHORT_PREVIEW_GET_LOAD',
  COHORT_PREVIEW_GET_SUCCESS = 'COHORT_PREVIEW_GET_SUCCESS',
  COHORT_PREVIEW_GET_FAILED = 'COHORT_PREVIEW_GET_FAILED',
  COHORT_PREVIEW_GET_CLEAR = 'COHORT_PREVIEW_GET_CLEAR'
}

export enum CohortSaveTypes {
  COHORT_SAVE_SET = 'COHORT_SAVE_SET',
  COHORT_SAVE_SET_LOAD = 'COHORT_SAVE_SET_LOAD',
  COHORT_SAVE_SET_SUCCESS = 'COHORT_SAVE_SET_SUCCESS',
  COHORT_SAVE_SET_FAILED = 'COHORT_SAVE_SET_FAILED',
  COHORT_SAVE_SET_CLEAR = 'COHORT_SAVE_SET_CLEAR'
}

export enum CohortChangeStatusTypes {
    COHORT_CHANGE_STATUS_SET = 'COHORT_CHANGE_STATUS_SET',
    COHORT_CHANGE_STATUS_SET_LOAD = 'COHORT_CHANGE_STATUS_SET_LOAD',
    COHORT_CHANGE_STATUS_SET_SUCCESS = 'COHORT_CHANGE_STATUS_SET_SUCCESS',
    COHORT_CHANGE_STATUS_SET_FAILED = 'COHORT_CHANGE_STATUS_SET_FAILED',
    COHORT_CHANGE_STATUS_SET_CLEAR= 'COHORT_CHANGE_STATUS_SET_FAILED'
  }

export enum GenerateCsvTypes {
    GENERATE_CSV_SET = 'GENERATE_CSV_SET',
    GENERATE_CSV_LOAD = 'GENERATE_CSV_LOAD',
    GENERATE_CSV_SUCCESS = 'GENERATE_CSV_SUCCESS',
    GENERATE_CSV_FAILED = 'GENERATE_CSV_FAILED',
    GENERATE_CSV_CLEAR = 'GENERATE_CSV_CLEAR'
}

/**
 * Data types
 */
interface JobRoleI {
    title: string
}

interface CompanyI {
    socialName: string
}

interface LocationI {
    states: string[]
    cities: string[]
    placesToWork?: string[]
}

interface StatusCohortI {
    name: ECohortStatus
    changedAt: Date
}

export interface CohortI {
  id: string
  jobRole: JobRoleI
  company: CompanyI
  slug: string
  total: number
  totalFilters: number
  location: LocationI
  status: StatusCohortI[]
  filterId: string
}

export interface PreviewState {
  id?: string
  jobRole?: JobRoleI
  company?: CompanyI
  slug?: string
  total?: number
  totalFilters?: number
  location?: LocationI
  status?: StatusCohortI[]
  filterId?: string
}

export enum EBgcApplicationStatus {
  NOT_STARTED = 'NOT_STARTED',
  ONGOING = 'ONGOING',
  VALID = 'VALID',
  INVALID = 'INVALID'
}

export interface SaveCohortI {
  active: boolean
  comapany: CompanyI
  filterId: string
  id: string
  jobRole: JobRoleI
  location: LocationI
  slug: string
  total: number
  totalFilters: number
  status: StatusCohortI[]
}

export enum EMei {
  YES = 'YES',
  MAYBE = 'MAYBE',
  NO = 'NO'
}

export enum EMeiDescription {
  NO = 'Não e não tenho interesse',
  MAYBE = 'Não, mas pretendo fazer',
  YES = 'Sim'
}

export enum EDaysOfWorkCode {
  ALL = 'ALL',
  MON = 'MON',
  TUE = 'TUE',
  WED = 'WED',
  THU = 'THU',
  FRI = 'FRI',
  SAT = 'SAT',
  SUN = 'SUN'
}

export enum EDaysOfWork {
  ALL = 'Todos',
  MON = 'Segunda',
  TUE = 'Terça',
  WED = 'Quarta',
  THU = 'Quinta',
  FRI = 'Sexta',
  SAT = 'Sábado',
  SUN = 'Domingo'
}

export enum StatusBankAccount {
  RECEIVED = 1,
  VALID_DATA = 5,
  INVALID_DATA = 6,
  NO_PROCESS = 7,
  PROCESSING = 98,
  NOT_VALIDATED = 99,
}

export interface CohortFilterI {
  adulthood?: boolean
  applicationStatus?: EApplicationStatus[]
  assessmentStatus?: EAssessmentStatus[]
  availablePeriod?: EAvailablePeriod[]
  availableSpace?: boolean | string
  bank?: boolean | string
  cities?: string[]
  cnh?: boolean | string
  companyId?: string
  courses?: boolean | string
  creci?: boolean | string
  degree?: EDegreeCode[]
  disability?: boolean | string
  employmentType?: EEmploymentCode[]
  experience?: boolean | string
  genders?: EGenderCode[]
  jobRoleId?: string
  mei?: boolean | string
  meiEnum?: EMei
  daysOfWork?: EDaysOfWorkCode[]
  isAssessmentStatusJamesApproved?: boolean | string
  isFormStatusJamesApproved?: boolean | string
  bankAccountStatus?: StatusBankAccount
  pis?: boolean | string
  proofAddress?: boolean | string
  placesToWork?: string[]
  smartphone?: EMobileOS[]
  states?: string[]
  tool?: boolean | string
  transportation?: ETransportationCode[]
  weekendAvailability?: boolean | string
  backgroundCheckStatus?: string
}

export interface CountCohort {
  ACCEPTED?: number,
  WAITING?: number,
  PENDING?: number,
  ACTIVATED?: number,
  DENIED?: number,
  VALIDATING?: number
}

/**
   * State type
   */
export interface CohortState {
    data?: CohortI[],
    previewData?: PreviewState,
    saveData?: SaveCohortI,
    changeStatusData?: CohortI,
    error: {
      message: string
    } | null,
    isLoading: boolean,
    success: boolean
  }

export interface CohortStatusChangeI {
      cohortId: string,
      status: ECohortStatus,
      feedback?: string
  }
