import { call, put, takeLatest } from 'redux-saga/effects'
import removeAuthToken from '../../utils/Authentication/removeAuthToken'

import * as services from './services'
import { AuthorizationTypes } from './types'

function * authorizationSet () {
  try {
    yield put({ type: AuthorizationTypes.AUTHORIZATION_LOAD })
    const authorizationData = yield call(services.authorizateUser)
    yield put({ type: AuthorizationTypes.AUTHORIZATION_SUCCESS, data: authorizationData })
  } catch (e) {
    yield removeAuthToken()
    yield put({ type: AuthorizationTypes.AUTHORIZATION_FAILED, payload: { error: e } })
  }
}

function * authorizationUnset () {
  try {
    yield put({ type: AuthorizationTypes.AUTHORIZATION_LOAD })
    yield removeAuthToken()
    yield put({ type: AuthorizationTypes.AUTHORIZATION_UNSET_SUCCESS })
  } catch (e) {
    yield put({ type: AuthorizationTypes.AUTHORIZATION_FAILED, payload: { error: e } })
  }
}

// This will watch for the actions dispatches
export default function * authorizationSaga () {
  yield takeLatest(AuthorizationTypes.AUTHORIZATION_SET, authorizationSet)
  yield takeLatest(AuthorizationTypes.AUTHORIZATION_UNSET, authorizationUnset)
}
