import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { RouteComponentProps } from 'react-router-dom'
import { Chip, MenuItem, Select, Typography } from '@material-ui/core'
import { FilterList } from '@material-ui/icons'
import {
  FormCheckbox,
  FormRadio,
  Spinner,
  StickyToast
} from '@mindlab-org/component-library'
import Sticky from 'react-sticky-el'

import * as routes from '../../routes/routes'
import FilterCounter from '../../components/FilterCounter'
import Header from '../../components/Header'
import { ApplicationState } from '../../redux/store'
import { CohortState, EDaysOfWork, EMei, EBgcApplicationStatus } from '../../redux/cohorts/types'
import { CompaniesState } from '../../redux/companies/types'
import { LocationState } from '../../redux/location/types'
import { FiltersState } from '../../redux/filters/types'
import { FunctionState } from '../../redux/functions/types'
import * as CohortActions from '../../redux/cohorts/actions'
import * as CompaniesActions from '../../redux/companies/actions'
import * as FiltersActions from '../../redux/filters/actions'
import * as FunctionActions from '../../redux/functions/actions'
import * as LocationActions from '../../redux/location/actions'
import { EGenderCode, EGenderDescription } from '../../utils/enums/gender.enum'
import { EAvailablePeriod, EAvailablePeriodDescription } from '../../utils/enums/available-period.enum'
import { ETransportationCode, ETransportation } from '../../utils/enums/transportation.enum'
import { EDegreeCode, EDegreeDescription } from '../../utils/enums/degree.enum'
import { EEmploymentCode, EEmploymentType } from '../../utils/enums/employment-type.enum'
import { EApplicationStatus } from '../../utils/enums/application-status.enum'
import { EAssessmentStatus } from '../../utils/enums/assessment-status.enum'
import PlacesToWorkSelector from './PlacesToWorkSelector'

const BACKGROUND_TITLES = [
  'Não iniciado',
  'Em Progresso',
  'Válido',
  'Inválido'
]
interface StateProps {
  cohorts: CohortState
  companies: CompaniesState
  filters: FiltersState
  functions: FunctionState
  locations: LocationState
}

interface DispatchProps {
  companiesSet(): void
  filtersSet(filters: object): void
  filtersClean(): void
  cohortPreviewClear(): void
  functionSet(id?: string): void
  locationSet(id?: string): void
  cohortPreviewSet(variables: object): void
}

interface RouteParams {
  id: string
}

interface OwnProps extends RouteComponentProps<RouteParams> { }

type Props = StateProps & DispatchProps & OwnProps

export interface FilterState {
  adulthood?: boolean
  applicationStatus: string[]
  assessmentStatus: string[]
  availablePeriod: string[]
  availableSpace?: boolean
  bank?: boolean
  cities: string[]
  cnh?: boolean
  companyId?: string
  courses?: boolean
  creci?: boolean
  degree: string[]
  disability?: boolean
  employmentType: string[]
  experience?: boolean
  genders: string[]
  jobRoleId?: string
  mei?: boolean
  meiEnum?: EMei
  daysOfWork?: EDaysOfWork[]
  assessmentStatusJames?: EAssessmentStatus[]
  bankAccountStatus?: string[],
  backgroundCheckStatus?: string,
  pis?: boolean
  proofAddress?: boolean
  placesToWork: string[]
  smartphone: string[]
  states: string[]
  tool?: boolean
  transportation: string[]
  weekendAvailability?: boolean
}

interface SelectComponent {
  lastState: string
}

interface State {
  fields: FilterState
  filtersActive: object,
  filtersCounter: number,
  selectComponent: SelectComponent,
  error: string | null,
  cleaning: boolean
}

interface Field {
  id: string
  title: string
  value: string
}

class CohortFilter extends Component<Props, State> {
  initialFields: FilterState = {
    adulthood: undefined,
    applicationStatus: [],
    assessmentStatus: [],
    availablePeriod: [],
    availableSpace: undefined,
    bank: undefined,
    cities: [],
    cnh: undefined,
    companyId: undefined,
    courses: undefined,
    creci: undefined,
    degree: [],
    disability: undefined,
    employmentType: [],
    experience: undefined,
    genders: [],
    jobRoleId: this.props.match.params.id,
    mei: undefined,
    meiEnum: undefined,
    assessmentStatusJames: [],
    daysOfWork: undefined,
    bankAccountStatus: [],
    backgroundCheckStatus: undefined,
    pis: undefined,
    proofAddress: undefined,
    placesToWork: [],
    smartphone: [],
    states: [],
    tool: undefined,
    transportation: [],
    weekendAvailability: undefined
  }

  state = {
    fields: this.initialFields,
    filtersActive: {},
    filtersCounter: 0,
    selectComponent: {
      lastState: ''
    },
    error: null,
    cleaning: false
  }

  componentDidMount () {
    const functionId = this.props.match.params.id
    this.props.functionSet(functionId)
    this.props.locationSet(functionId)
    this.props.companiesSet()
  }

  setFieldToState = (value: Field | Field[], field: string): void => {
    if (!Array.isArray(value)) {
      this.setState({
        fields: {
          ...this.state.fields,
          [field]: value.value
        }
      })
    } else {
      const finalValue = value.map(option => option.value)
      this.setState({
        fields: {
          ...this.state.fields,
          [field]: finalValue
        }
      })
    }
  }

  setSelectorToState = (value: any, field: string): void => {
    this.setState({
      fields: {
        ...this.state.fields,
        [field]: value
      }
    })
  }

  pushStateToState = (value: any, field: string): void => {
    const currentStates = this.state.fields.states
    const states = this.props.locations.data?.map(location => location.state)
    const newStates: string[] = currentStates.filter(state => state !== value)
    if (value.toString() === 'allStates' && states) {
      states.map(state => {
        if (newStates.indexOf(state) === -1) {
          newStates.push(state)
        }
      })
    } else {
      if (newStates.indexOf(value.toString()) === -1) {
        newStates.push(value.toString())
      }
    }
    this.setState({
      fields: {
        ...this.state.fields,
        [field]: newStates.flat()
      },
      selectComponent: {
        ...this.state.selectComponent,
        lastState: value
      }
    })
  }

  pushCityToState = (value: any, field: string): void => {
    const currentCities = this.state.fields[field]
    let newCities: string[] = currentCities.filter(city => city !== value)
    const citiesByState: string[] | undefined = this.findCitiesByState(this.state.selectComponent.lastState[0])
    const allCities: any[] | undefined = this.props.locations.data?.map(location => location.cities).flat()
    if (value.toString() === 'allCitiesByState' && citiesByState) {
      citiesByState.map(city => {
        if (newCities.indexOf(city) === -1) {
          newCities.push(city)
        }
      })
    } else {
      if (value.toString() === 'allCities' && allCities) {
        allCities.map(city => {
          if (newCities.indexOf(city) === -1) {
            newCities.push(city)
          }
        })
      } else {
        if (Array.isArray(value)) {
          newCities = value
        } else {
          newCities.push(value.toString())
        }
      }
    }
    this.setState({
      fields: {
        ...this.state.fields,
        [field]: newCities.flat()
      }
    })
  }

  removeCityByState = (state: string, field: string) => {
    const allCities: string[] = this.findCitiesByState(state)!
    let newCities: string[] = this.state.fields[field]
    allCities.map(city => {
      newCities = newCities.filter(location => location !== city)
    })
    return newCities
  }

  removeLocationFromState = (value: any, field: string): void => {
    const currentLocation = (field === 'states') ? (this.state.fields.states) : (this.state.fields[field])
    let filteredCities: string[]
    const newLocations: string[] = currentLocation.filter(location => location !== value)
    if (field) {
      this.setState({
        fields: {
          ...this.state.fields,
          [field]: newLocations
        }
      })
    } else {
      filteredCities = this.removeCityByState(value, field)
      this.setState({
        fields: {
          ...this.state.fields,
          [field]: newLocations,
          cities: filteredCities
        }
      })
    }
  }

  updateFilterData = () => {
    const { fields } = this.state
    const fieldList = Object.keys(fields)
    const activeFilters = {}
    let fieldCount = 0

    for (let i = 0; i < fieldList.length; i++) {
      const field = fieldList[i]
      const stateField = this.state.fields[field]

      if (Array.isArray(stateField)) {
        if (stateField.length > 0) {
          fieldCount++
          activeFilters[field] = stateField
        }
      } else {
        if (stateField !== undefined) {
          fieldCount++
          activeFilters[field] = stateField
        }
      }
    }
    this.props.filtersSet(activeFilters)
    this.setState({
      filtersActive: activeFilters,
      filtersCounter: fieldCount
    })
  }

  renderCompanySelector = () => {
    return (
      <div className="CohortFilter__Filters__Item CohortFilter__Filters__Item__Dropdown">
        <Typography>
          {'Empresa'}
        </Typography>
        <Select
          id="company"
          onChange={(e) => this.setSelectorToState(e.target.value, 'companyId')}
          value={this.state.fields.companyId || ''}>
          {
            this.props.companies.data?.map(company => (
              <MenuItem key={company.id} value={company.id}>{company.socialName}</MenuItem>
            ))
          }
        </Select>
      </div>
    )
  }

  renderStateSelector = () => {
    return (
      <div className="CohortFilter__Filters__Item CohortFilter__Filters__Item__Dropdown">
        <Typography>
          {'Estado'}
        </Typography>
        <Select
          id="states"
          onChange={(e) => this.pushStateToState([e.target.value], 'states')}
          value={(this.state.fields.states.length > 0) ? this.state.selectComponent.lastState : ''}>
          <MenuItem key={'allStates'} value={'allStates'}>Todos os Estados</MenuItem>
          {
            this.props.locations.data?.map(location => location.state).flat().map(states => (
              <MenuItem key={states} value={states}>{states}</MenuItem>
            ))
          }
        </Select>
        <div className="CohortFilter__Filters__Item__Chips">
          {
            this.state.fields.states.map(state => (
              <Chip
                key={state}
                label={state}
                onDelete={() => this.removeLocationFromState(state, 'states')}
              />
            ))
          }
        </div>
      </div>
    )
  }

  findCitiesByState = (state: string) => {
    return this.props.locations.data?.find(location => location.state === state)?.cities
  }

  renderCitySelector = () => {
    return (
      <div className="CohortFilter__Filters__Item CohortFilter__Filters__Item__Dropdown">
        <Typography>
          {'Cidade'}
        </Typography>
        <Select
          id="cities"
          onChange={(e) => this.pushCityToState(e.target.value, 'cities')}
          value={
            (this.state.fields.cities.length > 0)
              ? this.state.fields.cities[this.state.fields.cities.length - 1]
              : ''
          }>
          {(this.state.selectComponent.lastState.toString() !== 'allStates' && this.state.selectComponent.lastState !== '') ? (
            <MenuItem key={'allCitiesByState'} value={'allCitiesByState'}>{`Todas as cidades de ${this.state.selectComponent.lastState}`}</MenuItem>
          ) : (
            <MenuItem key={'allCities'} value={'allCities'}>Todas as cidades de Todos os Estados</MenuItem>
          )}
          {
            this.findCitiesByState(this.state.selectComponent.lastState[0])?.map(city => (
              <MenuItem key={city} value={city}>{city}</MenuItem>
            ))
          }
        </Select>
        <div className="CohortFilter__Filters__Item__Chips">
          {
            this.state.fields.cities.map(city => (
              <Chip
                key={city}
                label={city}
                onDelete={() => this.removeLocationFromState(city, 'cities')}
              />
            ))
          }
        </div>
      </div>
    )
  }

  renderPlacesToWorkSelector = () => {
    return <PlacesToWorkSelector
      fields={this.state.fields}
      removeLocationFromState={this.removeLocationFromState}
      pushCityToState={this.pushCityToState}
      jobRoleId={this.props.functions.data?.[0].id || ''}
    />
  }

  removeEmptyFilter = (stateKey: string) => {
    return (this.state.fields[stateKey].length < 1)
      ? undefined
      : this.state.fields[stateKey]
  }

  handleContinueButton = async () => {
    await this.onClickFilter()
    this.props.history.push(routes.PREVIEW_COHORT)
  }

  handleContinueButtonDisabled = (): boolean => {
    const { fields } = this.state
    return !(
      fields.companyId &&
      fields.jobRoleId
    )
  }

  onClickFilter = async () => {
    const { fields } = this.state

    const variables = {
      adulthood: fields.adulthood,
      applicationStatus: this.removeEmptyFilter('applicationStatus'),
      assessmentStatus: this.removeEmptyFilter('assessmentStatus'),
      availablePeriod: this.removeEmptyFilter('availablePeriod'),
      availableSpace: fields.availableSpace,
      bank: fields.bank,
      cities: fields.cities,
      cnh: fields.cnh,
      companyId: fields.companyId,
      courses: fields.courses,
      creci: fields.creci,
      degree: this.removeEmptyFilter('degree'),
      disability: fields.disability,
      employmentType: this.removeEmptyFilter('employmentType'),
      experience: fields.experience,
      genders: this.removeEmptyFilter('genders'),
      jobRoleId: fields.jobRoleId,
      mei: fields.mei,
      meiEnum: fields.meiEnum,
      assessmentStatusJames: this.removeEmptyFilter('assessmentStatusJames'),
      daysOfWork: fields.daysOfWork,
      bankAccountStatus: this.removeEmptyFilter('bankAccountStatus'),
      backgroundCheckStatus: fields.backgroundCheckStatus,
      pis: fields.pis,
      proofAddress: fields.proofAddress,
      placesToWork: fields.placesToWork,
      smartphone: this.removeEmptyFilter('smartphone'),
      states: fields.states,
      tool: fields.tool,
      transportation: this.removeEmptyFilter('transportation'),
      weekendAvailability: fields.weekendAvailability
    }

    if (
      fields.companyId &&
      fields.jobRoleId
    ) {
      this.updateFilterData()
      this.props.cohortPreviewSet(variables)
    }
  }

  mapOptions = (type: any, titles: string[] = [], elements: number[] = []) => {
    const keys = Object.keys(type)
    const values = titles.length ? titles : Object.values(type)
    return keys
      .filter((_, index) => {
        if (elements.length) {
          return elements.includes(index)
        }
        return true
      })
      .map((key, index) => {
        return {
          id: key + index,
          value: key,
          title: values[index]
        }
      })
  }

  renderItem = (item) => {
    return <div className="CohortFilter__Filters__Item">
      {item}
    </div>
  }

  renderWorkDays = () => {
    return this.renderItem(
      <FormCheckbox
        hasToolTip={false}
        onClickOption={(option) => this.setFieldToState(option.value, 'daysOfWork')}
        options={this.mapOptions(EDaysOfWork)}
        title={'Dias disponiveis'}
      />
    )
  }

  renderApplicationStatus = () => {
    return this.renderItem(
      <FormCheckbox
        hasToolTip={false}
        onClickOption={(option) => this.setFieldToState(option.value, 'applicationStatus')}
        options={this.mapOptions(EApplicationStatus, ['Em progresso', 'Pronto'], [1, 2])}
        title={'Status da candidatura'}
      />
    )
  }

  renderAssemmentsStatus = () => {
    return this.renderItem(
      <FormCheckbox
        hasToolTip={false}
        onClickOption={(option) => this.setFieldToState(option.value, 'assessmentStatus')}
        options={this.mapOptions(EAssessmentStatus, ['Aprovado', 'Reprovado'], [1, 2])}
        title={'Status da avaliação'}
      />
    )
  }

  renderAssemmentsStatusJames = () => {
    return this.renderItem(
      <FormCheckbox
        hasToolTip={false}
        onClickOption={(option) => this.setFieldToState(option.value, 'assessmentStatusJames')}
        options={this.mapOptions(EAssessmentStatus, ['Aprovado', 'Reprovado'], [1, 2])}
        title={'Status da avaliação James'}
      />
    )
  }

  renderFormStatusJames = () => {
    return this.renderItem(
      <FormCheckbox
        hasToolTip={false}
        onClickOption={(option) => this.setFieldToState(option.value, 'formStatusJames')}
        options={[
          {
            id: 'james-aprovado-form',
            title: 'Aprovado',
            value: true
          },
          {
            id: 'james-reprovado-form',
            title: 'Reprovado',
            value: false
          }
        ]}
        title={'Status do Formulario James'}
      />
    )
  }

  renderBankAccountStatus = () => {
    return this.renderItem(
      <FormCheckbox
        hasToolTip={false}
        onClickOption={(option) => this.setFieldToState(option.value, 'bankAccountStatus')}
        options={[
          {
            id: 'james-aprovado-bank',
            title: 'Aprovado',
            value: 'VALID_DATA'
          },
          {
            id: 'james-reprovado-bank',
            title: 'Reprovado',
            value: 'INVALID_DATA'
          }
        ]}

        title={'Conta Bancaria Validada?'}
      />
    )
  }

  renderBackgroundCheckStatus = () => {
    return this.renderItem(
      <FormRadio
        hasToolTip={false}
        onClickOption={(option) => this.setFieldToState(option, 'backgroundCheckStatus')}
        options={this.mapOptions(EBgcApplicationStatus, BACKGROUND_TITLES)}
        title={'Background Check'}
      />
    )
  }

  renderAdulthood = () => {
    return this.renderItem(
      <FormRadio
        hasToolTip={false}
        onClickOption={(option) => this.setFieldToState(option, 'adulthood')}
        options={[
          {
            id: 'adulthood-0',
            title: 'Sim',
            value: true
          },
          {
            id: 'adulthood-1',
            title: 'Não',
            value: false
          },
          {
            id: 'adulthood-2',
            title: 'Indiferente',
            value: undefined
          }
        ]}
        title={'Maior de 18 anos?'}
      />
    )
  }

  renderGenders = () => {
    return this.renderItem(
      <FormCheckbox
        hasToolTip={false}
        onClickOption={(option) => this.setFieldToState(option.value, 'genders')}
        options={this.mapOptions(EGenderCode, Object.values(EGenderDescription))}
        title={'Gênero'}
      />
    )
  }

  renderSmartphone = () => {
    return this.renderItem(
      <FormCheckbox
        hasToolTip={false}
        onClickOption={(option) => this.setFieldToState(option.value, 'smartphone')}
        options={[
          {
            id: 'ANDROID',
            title: 'Sim, Android',
            value: 'ANDROID'
          },
          {
            id: 'IOS',
            title: 'Sim, iOS',
            value: 'IOS'
          },
          {
            id: 'NONE',
            title: 'Não possuo',
            value: 'NONE'
          }
        ]}
        title={'Modelo de Smartphone'}
      />
    )
  }

  renderDegree = () => {
    return this.renderItem(
      <FormCheckbox
        hasToolTip={false}
        onClickOption={(option) => this.setFieldToState(option.value, 'degree')}
        options={this.mapOptions(EDegreeCode, Object.values(EDegreeDescription))}
        title={'Grau de Escolaridade'}
      />
    )
  }

  renderDisability = () => {
    return this.renderItem(
      <FormRadio
        hasToolTip={false}
        onClickOption={(option) => this.setFieldToState(option.value, 'disability')}
        options={[
          {
            id: 'disability-0',
            title: 'Sim',
            value: true
          },
          {
            id: 'disability-1',
            title: 'Não',
            value: false
          },
          {
            id: 'disability-2',
            title: 'Indiferente',
            value: undefined
          }
        ]}
        title={'Possui alguma deficiência?'}
      />
    )
  }

  renderProofAddress = () => {
    return this.renderItem(
      <FormRadio
        hasToolTip={false}
        onClickOption={(option) => this.setFieldToState(option, 'proofAddress')}
        options={[
          {
            id: 'proofAddress-0',
            title: 'Sim',
            value: true
          },
          {
            id: 'proofAddress-1',
            title: 'Não',
            value: false
          },
          {
            id: 'proofAddress-2',
            title: 'Indiferente',
            value: undefined
          }
        ]}
        title={'Possui comprovante de residência em seu nome?'}
      />
    )
  }

  renderWeekendAvailability = () => {
    return this.renderItem(
      <FormRadio
        hasToolTip={false}
        onClickOption={(option) => this.setFieldToState(option, 'weekendAvailability')}
        options={[
          {
            id: 'weekendAvailability-0',
            title: 'Sim',
            value: true
          },
          {
            id: 'weekendAvailability-1',
            title: 'Não',
            value: false
          },
          {
            id: 'weekendAvailability-2',
            title: 'Indiferente',
            value: undefined
          }
        ]}
        title={'Possui disponibilidade para trabalhar final de semana?'}
      />
    )
  }

  renderAvailablePeriod = () => {
    return this.renderItem(
      <FormCheckbox
        hasToolTip={false}
        onClickOption={(option) => this.setFieldToState(option.value, 'availablePeriod')}
        options={this.mapOptions(EAvailablePeriod, Object.values(EAvailablePeriodDescription))}
        title={'Período de trabalho'}
      />
    )
  }

  renderTransportation = () => {
    return this.renderItem(
      <FormCheckbox
        hasToolTip={false}
        onClickOption={(option) => this.setFieldToState(option.value, 'transportation')}
        options={this.mapOptions(ETransportationCode, Object.values(ETransportation))}
        title={'Veículo de trabalho'}
      />
    )
  }

  renderEmploymentType = () => {
    return this.renderItem(
      <FormCheckbox
        hasToolTip={false}
        onClickOption={(option) => this.setFieldToState(option.value, 'employmentType')}
        options={this.mapOptions(EEmploymentCode, Object.values(EEmploymentType))}
        title={'Modelo de trabalho'}
      />
    )
  }

  onCleanFilter = (event) => {
    event.preventDefault()
    this.setState({ ...this.state, cleaning: true },
      () => this.cleanFilter())
  }

  cleanFilter = () => {
    this.props.filtersClean()
    this.props.cohortPreviewClear()
    this.setState({
      fields: this.initialFields,
      filtersActive: {},
      filtersCounter: 0,
      selectComponent: {
        lastState: ''
      },
      cleaning: false
    })
  }

  render () {
    return (
      <div className="View CohortFilter">
        {this.props.cohorts.error && (
          <div style={{
            display: 'block',
            width: '100%'
          }}>
            <StickyToast show>
              <Typography style={{ color: '#ff4d4d' }}>
                {this.props.cohorts.error}
              </Typography>
            </StickyToast>
          </div>
        )}
        {this.state.error && (
          <div style={{
            display: 'block',
            width: '100%'
          }}>
            <StickyToast show>
              <Typography style={{ color: '#ff4d4d' }}>
                {this.state.error}
              </Typography>
            </StickyToast>
          </div>
        )}
        {
          this.props.functions.success &&
            !this.props.functions.isLoading ? (
              <>
                <Header title="Novo Cohort"
                  subtitle={this.props.functions.data?.[0].title}
                  onClickBack={() => this.props.history.push(routes.FUNCTION_LIST)}
                  onClickCancel={() => this.props.history.push(routes.HOMEPAGE)}
                />
                <div className="View__Background">
                  <div className="View__Background__Sides"></div>
                  <div className="View__Content--centered">
                    <div className="View__Title">
                      <div className="View__Title__Icon">
                        <FilterList
                          className="Header__Back__Icon" />
                      </div>
                      <Typography
                        variant="h2">
                      Filtros
                      </Typography>
                    </div>
                    {this.state.cleaning ? <Spinner/>
                      : <div className="CohortFilter__Filters">
                        {
                          this.props.companies.success &&
                        !this.props.companies.isLoading &&
                        this.renderCompanySelector()
                        }
                        {
                          this.props.locations.success &&
                        !this.props.locations.isLoading &&
                        this.renderStateSelector()
                        }
                        {
                          this.props.locations.success &&
                        !this.props.locations.isLoading &&
                        this.renderCitySelector()
                        }
                        {this.renderPlacesToWorkSelector()}
                        {this.renderWorkDays()}
                        {this.renderApplicationStatus()}
                        {this.renderAssemmentsStatus()}
                        {this.renderAssemmentsStatusJames()}
                        {this.renderFormStatusJames()}
                        {this.renderBankAccountStatus()}
                        {this.renderBackgroundCheckStatus()}
                        {this.renderAdulthood()}
                        {this.renderGenders()}
                        {this.renderSmartphone()}
                        {this.renderDegree()}
                        {this.renderDisability()}
                        {this.renderProofAddress()}
                        {this.renderWeekendAvailability()}
                        {this.renderAvailablePeriod()}
                        {this.renderTransportation()}
                        <div className="CohortFilter__Filters__Item">
                          <FormRadio
                            hasToolTip={false}
                            onClickOption={(option) => this.setFieldToState(option, 'bank')}
                            options={[
                              {
                                id: 'bank-0',
                                title: 'Sim',
                                value: true
                              },
                              {
                                id: 'bank-1',
                                title: 'Não',
                                value: false
                              },
                              {
                                id: 'bank-2',
                                title: 'Indiferente',
                                value: undefined
                              }
                            ]}
                            title={'Conta bancária em próprio nome'}
                          />
                        </div>
                        {this.renderEmploymentType()}
                        <div className="CohortFilter__Filters__Item">
                          <FormRadio
                            hasToolTip={false}
                            onClickOption={(option) => this.setFieldToState(option, 'mei')}
                            options={[
                              {
                                id: 'mei-0',
                                title: 'Sim',
                                value: true
                              },
                              {
                                id: 'mei-1',
                                title: 'Não',
                                value: false
                              },
                              {
                                id: 'mei-2',
                                title: 'Indiferente',
                                value: undefined
                              }
                            ]}
                            title={'MEI'}
                          />
                        </div>
                        <div className="CohortFilter__Filters__Item">
                          <FormRadio
                            hasToolTip={false}
                            onClickOption={(option) => this.setFieldToState(option, 'meiEnum')}
                            options={[
                              {
                                id: 'meiEnum-0',
                                title: 'Sim',
                                value: 'YES'
                              },
                              {
                                id: 'meiEnum-1',
                                title: 'Não',
                                value: 'NO'
                              },
                              {
                                id: 'meiEnum-2',
                                title: 'Talvez',
                                value: 'MAYBE'
                              }
                            ]}
                            title={'MEI James'}
                          />
                        </div>
                        <div className="CohortFilter__Filters__Item">
                          <FormRadio
                            hasToolTip={false}
                            onClickOption={(option) => this.setFieldToState(option, 'cnh')}
                            options={[
                              {
                                id: 'cnh-0',
                                title: 'Sim',
                                value: true
                              },
                              {
                                id: 'cnh-1',
                                title: 'Não',
                                value: false
                              },
                              {
                                id: 'cnh-2',
                                title: 'Indiferente',
                                value: undefined
                              }
                            ]}
                            title={'CNH'}
                          />
                        </div>
                        <div className="CohortFilter__Filters__Item">
                          <FormRadio
                            hasToolTip={false}
                            onClickOption={(option) => this.setFieldToState(option, 'pis')}
                            options={[
                              {
                                id: 'pis-0',
                                title: 'Sim',
                                value: true
                              },
                              {
                                id: 'pis-1',
                                title: 'Não',
                                value: false
                              },
                              {
                                id: 'pis-2',
                                title: 'Indiferente',
                                value: undefined
                              }
                            ]}
                            title={'PIS'}
                          />
                        </div>
                        <div className="CohortFilter__Filters__Item">
                          <FormRadio
                            hasToolTip={false}
                            onClickOption={(option) => this.setFieldToState(option, 'creci')}
                            options={[
                              {
                                id: 'creci-0',
                                title: 'Sim',
                                value: true
                              },
                              {
                                id: 'creci-1',
                                title: 'Não',
                                value: false
                              },
                              {
                                id: 'creci-2',
                                title: 'Indiferente',
                                value: undefined
                              }
                            ]}
                            title={'CRECI'}
                          />
                        </div>
                        <div className="CohortFilter__Filters__Item">
                          <FormRadio
                            hasToolTip={false}
                            onClickOption={(option) => this.setFieldToState(option, 'availableSpace')}
                            options={[
                              {
                                id: 'availableSpace-0',
                                title: 'Sim',
                                value: true
                              },
                              {
                                id: 'availableSpace-1',
                                title: 'Não',
                                value: false
                              },
                              {
                                id: 'availableSpace-2',
                                title: 'Indiferente',
                                value: undefined
                              }
                            ]}
                            title={'Espaço disponível para a função'}
                          />
                        </div>
                        <div className="CohortFilter__Filters__Item">
                          <FormRadio
                            hasToolTip={false}
                            onClickOption={(option) => this.setFieldToState(option, 'courses')}
                            options={[
                              {
                                id: 'courses-0',
                                title: 'Sim',
                                value: true
                              },
                              {
                                id: 'courses-1',
                                title: 'Não',
                                value: false
                              },
                              {
                                id: 'courses-2',
                                title: 'Indiferente',
                                value: undefined
                              }
                            ]}
                            title={'Cursos ou formação prévia para a função'}
                          />
                        </div>
                        <div className="CohortFilter__Filters__Item">
                          <FormRadio
                            hasToolTip={false}
                            onClickOption={(option) => this.setFieldToState(option, 'experience')}
                            options={[
                              {
                                id: 'experience-0',
                                title: 'Sim',
                                value: true
                              },
                              {
                                id: 'experience-1',
                                title: 'Não',
                                value: false
                              },
                              {
                                id: 'experience-2',
                                title: 'Indiferente',
                                value: undefined
                              }
                            ]}
                            title={'Experiência na função'}
                          />
                        </div>
                        <div className="CohortFilter__Filters__Item">
                          <FormRadio
                            hasToolTip={false}
                            onClickOption={(option) => this.setFieldToState(option, 'tool')}
                            options={[
                              {
                                id: 'tool-0',
                                title: 'Sim',
                                value: true
                              },
                              {
                                id: 'tool-1',
                                title: 'Não',
                                value: false
                              },
                              {
                                id: 'tool-2',
                                title: 'Indiferente',
                                value: undefined
                              }
                            ]}
                            title={'Ferramentas para função'}
                          />
                        </div>
                      </div>
                    }
                  </div>
                  <div className="View__Background__Sides">
                    <Sticky>
                      <FilterCounter
                        currentApplications={this.props.cohorts.previewData?.total || 0}
                        isButtonDisabled={this.handleContinueButtonDisabled()}
                        isLoading={this.props.cohorts.isLoading}
                        onClickContinue={() => this.handleContinueButton()}
                        onCleanFilter={(event) => this.onCleanFilter(event)}
                        onClickFilter={() => this.onClickFilter()}
                        totalFilters={this.props.cohorts.previewData?.totalFilters || this.state.filtersCounter}
                        totalApplications={this.props.functions.data?.[0].totalApplications || 0}
                      />
                    </Sticky>
                  </div>
                </div>
              </>
            ) : (
              <Spinner spinnerArea="50vh" />
            )
        }
      </div>
    )
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => ({
  cohorts: state.cohorts,
  companies: state.companies,
  filters: state.filters,
  functions: state.functions,
  locations: state.location
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => bindActionCreators(
  {
    ...CohortActions,
    ...CompaniesActions,
    ...FiltersActions,
    ...FunctionActions,
    ...LocationActions
  }, dispatch
)

export default connect(mapStateToProps, mapDispatchToProps)(CohortFilter)
