import { Reducer } from 'redux'
import { AuthorizationTypes, AuthorizationState } from './types'

const INITAL_STATE = {
  data: undefined,
  isLoading: false,
  isAuth: false,
  error: null,
  success: false
}

const authorizationReducer: Reducer<AuthorizationState> = (state = INITAL_STATE, action) => {
  switch (action.type) {
    case AuthorizationTypes.AUTHORIZATION_SET:
      return {
        ...state,
        data: action.data
      }

    case AuthorizationTypes.AUTHORIZATION_UNSET:
      return {
        ...state
      }

    case AuthorizationTypes.AUTHORIZATION_UNSET_SUCCESS:
      return {
        ...state,
        ...INITAL_STATE
      }

    case AuthorizationTypes.AUTHORIZATION_LOAD:
      return {
        ...state,
        isLoading: true,
        success: false
      }

    case AuthorizationTypes.AUTHORIZATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuth: true,
        success: true,
        data: action.data
      }

    case AuthorizationTypes.AUTHORIZATION_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        success: false,
        isAuth: false
      }
    default:
      return state
  }
}

export default authorizationReducer
