import React from 'react'
import Logo from '../../utils/style/logo.svg'
import { ViewElement } from '../DynamicPage/ViewElement'
import { ComponentProcessor } from '../DynamicPage/ComponentProcessor'
import { Button, themes, Typography } from '@mindlab-org/component-library'

const processor = ComponentProcessor.buildProcessor()
interface OwnProps {
  cohortCount?: number;
  onClickButton();
  onClickLogout();
  title: string;
  operator: boolean;
  buttons: ViewElement[];
}

const renderDynamicHeaderButtons = (
  buttons: ViewElement[],
  processor: ComponentProcessor
) => {
  return (
    <div className="HeaderHome__Right__Dynamic">
      {buttons.map((button, index) => {
        return (
          <div key={index} className="HeaderHome__Right__Dynamic__Button">
            {processor.processComponent(button)}
          </div>
        )
      })}
    </div>
  )
}

const HeaderHome = ({
  onClickButton,
  onClickLogout,
  title,
  operator,
  buttons
}: OwnProps) => {
  const renderStaticHeaderButton = () => {
    return (
      <>
        <div className="HeaderHome__Right__Logout">
          <Button
            backgroundColor={'#B8B5C6'}
            padding={'8px 16px'}
            textColor={'#ffffff'}
            retangular={true}
            onButtonClick={onClickLogout}
            hoverColor={'#c3c2c7'}
          >
            <Typography
              fontSize={'14px'}
              lineHeight={'18px'}
              letterSpacing={'1px'}
              color={themes.vojo.colors.whiteColor}
            >
              SAIR
            </Typography>
          </Button>
        </div>
        <div className="HeaderHome__Right__Buttons">
          <Button
            retangular={true}
            backgroundColor={'#391DDD'}
            onButtonClick={onClickButton}
            padding={'9px 16px'}
          >
            <Typography
              fontSize={'14px'}
              lineHeight={'18px'}
              letterSpacing={'1px'}
              fontWeight={'500'}
              color={themes.vojo.colors.whiteColor}
            >
              {operator ? 'NOVO COHORT' : 'Solicitar Candidatos'}
            </Typography>
          </Button>
        </div>
      </>
    )
  }

  return (
    <div className="HeaderHome">
      <div className="HeaderHome__Container">
        <div className="HeaderHome__Left">
          <img src={Logo} alt="Logo Vojo" />
          <div>
            <div className="HeaderHome__Left__Title">
              <Typography
                fontWeight={500}
                fontSize= '1em'
                color={'#9E9E9E'}
              >
              Gerenciador VOJO
              </Typography>
            </div>
            <Typography
              fontSize={'42px'}
              type={'title'}
              lineHeight={'38px'}>
              {title && title.toUpperCase()}
            </Typography>
          </div>
        </div>
        <div className="HeaderHome__Right">
          {renderStaticHeaderButton()}
          {buttons && buttons.length !== 0 && renderDynamicHeaderButtons(buttons, processor)}
        </div>
      </div>
    </div >
  )
}

export default HeaderHome
