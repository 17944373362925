export enum EEmploymentCode {
  AUTONOMO = 'AUTONOMO',
  AUTONOMOAPP = 'AUTONOMOAPP',
  CLT = 'CLT',
  CLTTEMP = 'CLTTEMP',
  FREELANCER = 'FREELANCER',
  TERCEIRIZADO = 'TERCEIRIZADO',
  PJ = 'PJ'
}

export enum EEmploymentType {
  AUTONOMO = 'Autônomo',
  AUTONOMOAPP = 'Autônomo por aplicativo',
  CLT = 'CLT',
  CLTTEMP = 'CLT (Temporário)',
  FREELANCER = 'Freelancer',
  TERCEIRIZADO = 'Terceirizado',
  PJ = 'Prestador de Serviço (PJ)'
}
