import VojoApi from '../../services/VojoApi'
import setAuthToken from '../../utils/Authentication/setAuthToken'
import { LoginI } from './types'

export const loginUser = async (data: LoginI) => {
  try {
    const response = await new VojoApi().instance.post(
      '/v3/auth/login',
      { ...data, admin: true },
      {
        headers:
        { 'X-Custom-Header': 'ProcessThisImmediately' }
      }
    )
    const authToken = response.headers['vojo-authorization']
    setAuthToken(authToken)
    return response
  } catch (errResponse) {
    const error = errResponse.response.data.error
    throw error
  }
}
