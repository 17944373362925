/* eslint-disable react/prop-types */
// eslint-disable-next-line no-use-before-define
import React, { FC } from 'react'
import { Chip, MenuItem, Select, Typography } from '@material-ui/core'
import useQuery from '../../hooks/useQuery'
import { FilterState } from './CohortFilter'

export type Props = {
  pushCityToState: (value: any, field: string) => void;
  fields: FilterState;
  removeLocationFromState: (value: any, field: string) => void;
  jobRoleId: string;
};

const PlacesToWorkSelector: FC<Props> = ({
  pushCityToState,
  fields,
  removeLocationFromState,
  jobRoleId
}) => {
  const [isLoading, error, response] = useQuery({ method: 'GET', url: `v3/jobs/?role=${jobRoleId}&mergeLocations=true` }, !jobRoleId)

  const renderSelectedLocations = () => {
    const selectedLocations = response.data[0].locations.filter(location => fields.placesToWork.includes(location.value))
    return selectedLocations.map(({ value, description }) => {
      return <Chip
        key={value}
        label={description}
        onDelete={() => removeLocationFromState(value, 'placesToWork')}
      />
    })
  }

  if (isLoading) return null
  if (error) {
    alert(error)
    return <></>
  }
  return response && (
    <div className="CohortFilter__Filters__Item CohortFilter__Filters__Item__Dropdown">
      <Typography>
        {'Cidades disponíveis para trabalhar'}
      </Typography>
      <Select
        id="placesToWork"
        onChange={(e) => pushCityToState(e.target.value, 'placesToWork')}
        value={
          fields.placesToWork[fields.placesToWork.length - 1] || ''
        }>
        <MenuItem key={'allPlaceToWork'} value={response.data[0].locations.map(location => location.value)}>Todas as cidades disponíveis para trabalhar</MenuItem>
        {
          response.data.map(job => (
            job.locations.map(({ value, description }) => <MenuItem key={value} value={value}>{description}</MenuItem>
            )))
        }
      </Select>
      <div className="CohortFilter__Filters__Item__Chips">
        {fields.placesToWork.length > 0 && renderSelectedLocations()}
      </div>
    </div>
  )
}

export default PlacesToWorkSelector
