import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import * as routes from '../routes'

const AuthorizedRoute = (props: any) => {
  return !props.authorization.isAuth || !localStorage.getItem('authToken')
    ? <Redirect to={routes.LOGIN}/>
    : <Route {...props}/>
}

const mapStateToProps = state => ({
  authorization: state.authorization
})

export default connect(mapStateToProps)(AuthorizedRoute)
