import { Reducer } from 'redux'
import { LocationState, LocationTypes } from './types'

const INITAL_STATE: LocationState = {
  data: undefined,
  isLoading: false,
  error: null,
  success: false
}

const locationReducer: Reducer<LocationState> = (state = INITAL_STATE, action) => {
  switch (action.type) {
    case LocationTypes.LOCATION_GET:
      return {
        ...state
      }

    case LocationTypes.LOCATION_GET_LOAD:
      return {
        ...state,
        isLoading: true,
        success: false
      }

    case LocationTypes.LOCATION_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        success: true
      }

    case LocationTypes.LOCATION_GET_FAILED:
      return {
        ...state,
        isLoading: false,
        data: [],
        error: action.error,
        success: false
      }

    default: return state
  }
}

export default locationReducer
