// import Axios from 'axios'
import VojoApi from '../../services/VojoApi'
import VojoApollo from '../../services/VojoApollo'
import handleErrors from '../../utils/middlewares/handleErrors'
import { COHORT_CHANGE_STATUS, FIND_COHORTS, GENERATE_CSV, PREVIEW_COHORT, SAVE_COHORT, COUNT_COHORTS } from './queries'
import { CohortFilterI, CohortStatusChangeI } from './types'
import { ECohortStatus } from '../../utils/enums/cohort-status.enum'

export const getCohorts = async (variables) => {
  const vojoApi = new VojoApi()
  vojoApi.createInstance()
  const { data, error } = (await VojoApollo.query({
    query: FIND_COHORTS,
    variables: variables
  }))

  if (data.errors?.length > 0) {
    handleErrors(data.errors[0].extensions.exception)
    return data.errors
  }
  if (error) return error
  return data.findCohorts
}

export const countCohorts = async (status: ECohortStatus[]) => {
  const { data, error } = await VojoApollo.query({
    query: COUNT_COHORTS,
    variables: { status }
  })
  if (data.errors?.length > 0) {
    handleErrors(data.errors[0].extensions.exception)
    return data.errors
  }
  return error || data.countCohorts
}

export const getCohortsPreview = async (variables: CohortFilterI) => {
  const { data, error } = await VojoApollo.query({
    query: PREVIEW_COHORT,
    variables: variables
  })
  if (data.errors?.length) {
    handleErrors(data.errors[0].extensions.exception)
    return data.errors
  }
  if (error) return error
  return data.previewCohort
}

export const setCohortsSave = async (variables: CohortFilterI) => {
  const { data, error } = await VojoApollo.query({
    query: SAVE_COHORT,
    variables: variables
  })
  if (data.errors?.length > 0) {
    handleErrors(data.errors[0].extensions.exception)
    return data.errors
  }
  if (error) return error
  return data.saveCohort
}

export const setCohortStatusChange = async (variables: CohortStatusChangeI) => {
  const { data } = await VojoApollo.mutate({
    mutation: COHORT_CHANGE_STATUS,
    variables: variables
  })
  if (data.errors?.length > 0) {
    handleErrors(data.errors[0].extensions.exception)
    return data.errors
  }
  await VojoApollo.cache.reset()
  return data.changeStatus
}

export const generateStringCsv = async (variables: CohortFilterI) => {
  const { data, error } = await VojoApollo.query({
    query: GENERATE_CSV,
    variables: variables,
    fetchPolicy: 'network-only'
  })
  if (data.errors?.length > 0) {
    handleErrors(data.errors[0].extensions.exception)
    return data.errors
  }
  if (error) return error
  return data
}
