import { call, put, takeLatest } from 'redux-saga/effects'

import * as services from './services'
import { CohortTypes, CohortPreviewTypes, CohortSaveTypes, CohortChangeStatusTypes, GenerateCsvTypes } from './types'

function * loadCohorts (action) {
  try {
    yield put({ type: CohortTypes.COHORT_GET_LOAD })
    const data = yield call(services.getCohorts, action.payload)
    yield put({ type: CohortTypes.COHORT_GET_SUCCESS, data: data })
  } catch (err) {
    yield put({ type: CohortTypes.COHORT_GET_FAILED, error: err })
  }
}

function * loadCohortsPreview (action) {
  try {
    yield put({ type: CohortPreviewTypes.COHORT_PREVIEW_GET_LOAD })
    const data = yield call(services.getCohortsPreview, action.payload)
    yield put({ type: CohortPreviewTypes.COHORT_PREVIEW_GET_SUCCESS, data: data })
  } catch (err) {
    yield put({ type: CohortPreviewTypes.COHORT_PREVIEW_GET_FAILED, error: err })
  }
}

function * loadCohortsSave (action) {
  try {
    yield put({ type: CohortSaveTypes.COHORT_SAVE_SET_LOAD })
    const data = yield call(services.setCohortsSave, action.payload)
    yield put({ type: CohortSaveTypes.COHORT_SAVE_SET_SUCCESS, data: data })
  } catch (err) {
    yield put({ type: CohortSaveTypes.COHORT_SAVE_SET_FAILED, error: err })
  }
}

function * loadCohortChangeStatus (action) {
  try {
    yield put({ type: CohortChangeStatusTypes.COHORT_CHANGE_STATUS_SET_LOAD })
    const data = yield call(services.setCohortStatusChange, action.payload)
    yield put({ type: CohortChangeStatusTypes.COHORT_CHANGE_STATUS_SET_SUCCESS, data: data })
  } catch (err) {
    yield put({ type: CohortChangeStatusTypes.COHORT_CHANGE_STATUS_SET_FAILED, error: err })
  }
}

function * loadGenerateCsv (action) {
  try {
    yield put({ type: GenerateCsvTypes.GENERATE_CSV_LOAD })
    const data = yield call(services.generateStringCsv, action.payload)
    yield put({ type: GenerateCsvTypes.GENERATE_CSV_SUCCESS, data: data })
  } catch (err) {
    yield put({ type: GenerateCsvTypes.GENERATE_CSV_FAILED, error: err })
  }
}

// This will watch for the actions dispatches
export default function * cohortsSaga () {
  yield takeLatest(CohortTypes.COHORT_GET, loadCohorts)
  yield takeLatest(CohortPreviewTypes.COHORT_PREVIEW_GET, loadCohortsPreview)
  yield takeLatest(CohortSaveTypes.COHORT_SAVE_SET, loadCohortsSave)
  yield takeLatest(CohortChangeStatusTypes.COHORT_CHANGE_STATUS_SET, loadCohortChangeStatus)
  yield takeLatest(GenerateCsvTypes.GENERATE_CSV_SET, loadGenerateCsv)
}
