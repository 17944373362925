
import history from '../../routes/history'
import { LOGIN } from '../../routes/routes'
import removeAuthToken from '../Authentication/removeAuthToken'

const UNAUTHORIZED = 401
const FORBIDDEN = 403

const handleErrors = (error) => {
  if (error.status === UNAUTHORIZED || error.status === FORBIDDEN) {
    removeAuthToken()
    return history.push(LOGIN)
  }
}

export default handleErrors
