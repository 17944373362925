import React from 'react'
import { Modal, Typography } from '@material-ui/core'

interface Props {
  title:string,
  body: any,
  actions: any[],
  open: boolean
}

function ModalAction ({
  title,
  body,
  actions,
  open
}: Props) {
  return (
    <Modal
      open={open}
      onClose={() => {}}
    >
      <div className="ModalAction">
        <div className="ModalAction__Content">
          <Typography variant="h3">
            {title}
          </Typography>
          {body}
        </div>
        <div className="ModalAction__Buttons">
          {actions}
        </div>
      </div>
    </Modal>
  )
}

export default ModalAction
