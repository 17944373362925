import { action } from 'typesafe-actions'
import { FunctionTypes, FunctionI } from './types'

export const functionSet = (functionId?: string) => action(FunctionTypes.FUNCTION_GET, functionId)

export const functionLoad = () => action(FunctionTypes.FUNCTION_GET_LOAD)

export const functionSuccess = (data: FunctionI[]) =>
  action(FunctionTypes.FUNCTION_GET_SUCCESS, data)

export const functionFailed = () => action(FunctionTypes.FUNCTION_GET_FAILED)
