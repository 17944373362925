/**
 * ACTION TYPES
 */
export enum FunctionTypes {
  FUNCTION_GET = 'FUNCTION_GET',
  FUNCTION_GET_LOAD = 'FUNCTION_GET_LOAD',
  FUNCTION_GET_SUCCESS = 'FUNCTION_GET_SUCCESS',
  FUNCTION_GET_FAILED = 'FUNCTION_GET_FAILED'
}

/**
 * Data types
 */
export interface FunctionI {
    id: string
    totalApplications: number
    title: string,
    slug: string
}

/**
 * State type
 */
export interface FunctionState {
  data?: FunctionI[],
  error: {
    message: string
  } | null,
  isLoading: boolean,
  success: boolean
}
