import { action } from 'typesafe-actions'
import { CohortTypes, CohortPreviewTypes, CohortI, CohortSaveTypes, CohortChangeStatusTypes, CohortStatusChangeI, GenerateCsvTypes } from './types'

// COHORT SET
export const cohortSet = (data) => action(CohortTypes.COHORT_GET, data)

export const cohortLoad = () => action(CohortTypes.COHORT_GET_LOAD)

export const cohortSuccess = (data: CohortI[]) =>
  action(CohortTypes.COHORT_GET_SUCCESS, data)

export const cohortFailed = () => action(CohortTypes.COHORT_GET_FAILED)

export const cohortClear = () => action(CohortTypes.COHORT_GET_CLEAR)

// COHORT PREVIEW
export const cohortPreviewSet = (filter: object) => action(CohortPreviewTypes.COHORT_PREVIEW_GET, filter)

export const cohortPreviewLoad = () => action(CohortPreviewTypes.COHORT_PREVIEW_GET_LOAD)

export const cohortPreviewSuccess = (data: CohortI[]) =>
  action(CohortPreviewTypes.COHORT_PREVIEW_GET_SUCCESS, data)

export const cohortPreviewFailed = () => action(CohortPreviewTypes.COHORT_PREVIEW_GET_FAILED)

export const cohortPreviewClear = () => action(CohortPreviewTypes.COHORT_PREVIEW_GET_CLEAR)

// COHORT SAVE
export const cohortSaveSet = (filter: object) => action(CohortSaveTypes.COHORT_SAVE_SET, filter)

export const cohortSaveLoad = () => action(CohortSaveTypes.COHORT_SAVE_SET_LOAD)

export const cohortSaveSuccess = (data: CohortI[]) =>
  action(CohortSaveTypes.COHORT_SAVE_SET_SUCCESS, data)

export const cohortSaveFailed = () => action(CohortSaveTypes.COHORT_SAVE_SET_FAILED)

export const cohortSaveClear = () => action(CohortSaveTypes.COHORT_SAVE_SET_CLEAR)

// COHORT CHANGE STATUS
export const cohortChangeStatusSet = (data: CohortStatusChangeI) =>
  action(CohortChangeStatusTypes.COHORT_CHANGE_STATUS_SET, data)

export const cohortChangeStatusLoad = () => action(CohortChangeStatusTypes.COHORT_CHANGE_STATUS_SET_LOAD)

export const cohortChangeStatusSuccess = (data: CohortI) => action(CohortChangeStatusTypes.COHORT_CHANGE_STATUS_SET_SUCCESS, data)

export const cohortChangeStatusFailed = () => action(CohortChangeStatusTypes.COHORT_CHANGE_STATUS_SET_FAILED)

export const cohortChangeStatusClear = () => action(CohortChangeStatusTypes.COHORT_CHANGE_STATUS_SET_CLEAR)

// GENERATE CSV (BY FILTER)
export const generateCsvSet = (filter: object) => action(GenerateCsvTypes.GENERATE_CSV_SET, filter)

export const generateCsvLoad = () => action(GenerateCsvTypes.GENERATE_CSV_LOAD)

export const generateCsvSuccess = (data: string) =>
  action(GenerateCsvTypes.GENERATE_CSV_SUCCESS, data)

export const generateCsvFailed = () => action(GenerateCsvTypes.GENERATE_CSV_FAILED)

export const generateCsvClear = () => action(GenerateCsvTypes.GENERATE_CSV_CLEAR)
