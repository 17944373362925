import { UserRole } from '../enums/user-role.enum'
import getUserData from './getUserData'

export default () : UserRole => {
  const userData = getUserData()

  return userData.roles.includes(UserRole.OPERATOR)
    ? UserRole.OPERATOR
    : UserRole.MANAGER
}
