export enum ETransportationCode {
  NONE = 'NONE',
  BIKE = 'BIKE',
  MOTORCYCLE = 'MOTORCYCLE',
  CAR = 'CAR',
  FIORINO = 'FIORINO',
  VAN = 'VAN',
  TRUCKHR = 'TRUCKHR',
  TRUCKBOX = 'TRUCKBOX',
  TRUCK34 = 'TRUCK34',
  SCOOTER = 'SCOOTER',
  PICKUP_TRUCKER = 'PICKUP_TRUCKER'
}

export enum ETransportation {
  NONE = 'não possuo',
  BIKE = 'bicicleta',
  MOTORCYCLE = 'moto',
  CAR = 'carro de passeio',
  FIORINO = 'fiorino',
  VAN = 'van de carga',
  TRUCKHR = 'caminhão HR',
  TRUCKBOX = 'caminhão baú',
  TRUCK34 = 'caminhão 3/4',
  SCOOTER = 'patinete',
  PICKUP_TRUCKER = 'caminhonete'
}