import { combineReducers } from 'redux'
import cohortsReducer from './cohorts'
import companiesReducer from './companies'
import filtersReducer from './filters'
import functionReducer from './functions'
import locationReducer from './location'
import loginReducer from './login'
import authorizationReducer from './authorization'

const rootReducer = combineReducers({
  cohorts: cohortsReducer,
  companies: companiesReducer,
  filters: filtersReducer,
  functions: functionReducer,
  location: locationReducer,
  login: loginReducer,
  authorization: authorizationReducer
})

export default rootReducer

export type RootReducer = ReturnType<typeof rootReducer>
