export enum LoginTypes {
    LOGIN_SET = 'LOGIN_SET',
    LOGIN_LOAD = 'LOGIN_LOAD',
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    LOGIN_FAILED = 'LOGIN_FAILED'
}

export interface LoginI {
    username?: string
    password?: string,
    admin: boolean
}

export interface LoginState {
    error: {
      message: string
    } | null,
    isLoading: boolean,
    success: boolean
  }
