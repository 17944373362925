import { useEffect, useReducer } from 'react'
import VojoApi from '../services/VojoApi'

const useQueryReducer = (state, { type, payload }) => {
  const stateChanges = {}

  switch (type) {
    case 'FETCH_START':
      stateChanges.status = 'LOADING'
      stateChanges.error = null
      break
    case 'FETCH_FAILURE':
      stateChanges.status = 'FAILURE'
      stateChanges.error = payload
      break
    case 'FETCH_SUCCESS':
      stateChanges.status = 'SUCCESS'
      stateChanges.data = payload
      stateChanges.error = null
      break
    default:
      throw new Error('useQuery received invalid action type from dispatch')
  }

  return { ...state, ...stateChanges }
}

const initialState = { status: 'LOADING', data: undefined, error: undefined }

/**
 * @description This hook receives a config object and returns reactive values that describes the state of the query.
 * @param {import('axios').AxiosRequestConfig} Query Must contain HTTP method, Url, and optionally query data.
 * @param {boolean} skip if true query will not be executed.
 * @returns {[
 * isLoading: boolean,
 * error: import('axios').AxiosError
 * data: any,
 * ]} This values are reactive. You may use them without an aditional useState() call
 */
const useQuery = ({ method, url, data }, skip = false) => {
  const [state, dispatch] = useReducer(useQueryReducer, initialState)

  let isMounted = true

  const runQuery = async () => {
    if (skip) return
    dispatch({ type: 'FETCH_START' })

    try {
      const result = await new VojoApi().instance({ method, url, data })

      if (isMounted) dispatch({ type: 'FETCH_SUCCESS', payload: result.data })
    } catch (error) {
      if (isMounted) dispatch({ type: 'FETCH_FAILURE', payload: error })
    }
  }

  useEffect(() => {
    runQuery()

    return () => { isMounted = false }
  }, [method, url, data])

  const isLoading = state.status === 'LOADING'

  return [isLoading, state.error, state.data]
}

export default useQuery
