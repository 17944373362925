import { action } from 'typesafe-actions'
import { LocationTypes, LocationI } from './types'

export const locationSet = (functionId?: string) => action(LocationTypes.LOCATION_GET, functionId)

export const locationLoad = () => action(LocationTypes.LOCATION_GET_LOAD)

export const locationSuccess = (data: LocationI[]) =>
  action(LocationTypes.LOCATION_GET_SUCCESS, data)

export const locationFailed = () => action(LocationTypes.LOCATION_GET_FAILED)
