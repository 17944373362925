import { ViewElement } from '../ViewElement'
import React, { Component } from 'react'

export abstract class AbstractComponentHandler {
  constructor (protected renderedComponent: JSX.Element) {

  }

    /**
     * Método que decide se o tratador atual **deve** renderizar o component
    */
    abstract accepts(type: ViewElement): boolean
    abstract renderComponent(component: ViewElement): JSX.Element
}
