import VojoApi from '../../services/VojoApi'
import setUserData from '../../utils/UserData/setUserData'

export const authorizateUser = async () => {
  try {
    const response = await new VojoApi().instance.get('/v3/auth/me')
    const authData = response.data.data
    setUserData(authData)
    return authData
  } catch (errResponse) {
    const error = errResponse.response.data.error
    throw error
  }
}
