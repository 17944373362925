import { Reducer } from 'redux'
import { LoginTypes, LoginState } from './types'

const INITAL_STATE = {
  isLoading: false,
  error: null,
  success: false
}

const loginReducer: Reducer<LoginState> = (state = INITAL_STATE, action) => {
  switch (action.type) {
    case LoginTypes.LOGIN_SET:
      return {
        ...state
      }

    case LoginTypes.LOGIN_LOAD:
      return {
        ...state,
        isLoading: true,
        success: false
      }

    case LoginTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        data: action.data
      }

    case LoginTypes.LOGIN_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        success: false
      }
    default:
      return state
  }
}

export default loginReducer
