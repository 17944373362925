import { call, put, takeLatest } from 'redux-saga/effects'

import * as services from './services'
import { FunctionTypes } from './types'

function * loadFunctions (action) {
  try {
    yield put({ type: FunctionTypes.FUNCTION_GET_LOAD })
    const data = yield call(services.getFunctions, action.payload)
    yield put({ type: FunctionTypes.FUNCTION_GET_SUCCESS, data: data })
  } catch (err) {
    yield put({ type: FunctionTypes.FUNCTION_GET_FAILED, error: err })
  }
}

// This will watch for the actions dispatches
export default function * functionsSaga () {
  yield takeLatest(FunctionTypes.FUNCTION_GET, loadFunctions)
}
