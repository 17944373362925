import { Reducer } from 'redux'
import { CompaniesState, CompaniesTypes } from './types'

const INITAL_STATE: CompaniesState = {
  data: undefined,
  isLoading: false,
  error: null,
  success: false,
  requestCohort: {
    isLoading: false,
    error: null,
    success: false
  }
}

const companiesReducer: Reducer<CompaniesState> = (state = INITAL_STATE, action) => {
  return {
    [CompaniesTypes.COMPANIES_GET] : {
      ...state
    },
    [CompaniesTypes.COMPANIES_GET_LOAD] : {
      ...state,
      isLoading: true,
      success: false
    },
    [CompaniesTypes.COMPANIES_GET_SUCCESS]: {
      ...state,
      isLoading: false,
      data: action.data,
      success: true
    },
    [CompaniesTypes.COMPANIES_GET_FAILED]: {
      ...state,
      isLoading: false,
      data: [],
      error: action.error,
      success: false
    },
    [CompaniesTypes.REQUEST_COHORT]: {
      ...state
    },
    [CompaniesTypes.REQUEST_COHORT_LOAD]: {
      ...state,
      requestCohort: {
        ...state.requestCohort,
        isLoading: true,
        success: false
      }
    },
    [CompaniesTypes.REQUEST_COHORT_SUCCESS]: {
      ...state,
      requestCohort: {
        ...state.requestCohort,
        isLoading: false,
        success: true
      }
    },
    [CompaniesTypes.REQUEST_COHORT_FAILED]: {
      ...state,
      requestCohort: {
        ...state.requestCohort,
        isLoading: false,
        error: action.error,
        success: false
      }
    }
  }[action.type] || state
}

export default companiesReducer
