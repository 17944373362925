import { AbstractComponentHandler } from './AbstractComponentHandler'
import { ViewElement } from '../ViewElement'
import React from 'react'
import { Button, themes, Typography } from '@mindlab-org/component-library'

export class ButtonComponentHandler extends AbstractComponentHandler {
  constructor (protected renderedComponent = Button) {
    super(renderedComponent)
  }

  accepts (component: ViewElement): boolean {
    return component.component.type === 'button'
  }

  renderComponent (component: ViewElement) {
    const ComponentToRender = this.renderedComponent

    return <ComponentToRender
      retangular={true}
      hoverColor={'#dcd8f2'}
      backgroundColor={themes.vojo.colors.lightPurpleColor}
      onButtonClick={() => window.open(component.value)}
      padding={'9px 14px'}
      id={component.component.code}>
      <Typography
        fontSize={'14px'}
        lineHeight={'18px'}
        fontWeight={'600'}
        color={themes.vojo.colors.primaryColor}
        letterSpacing={'1px'}
      >
        {component.title}
      </Typography>
    </ComponentToRender>
  }
}
