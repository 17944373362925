import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import * as routes from '../routes'

const UnauthorizedRoute = (props: any) => {
  return props.authorizationData.isAuth && localStorage.getItem('authToken')
    ? <Redirect to={routes.HOMEPAGE}/>
    : <Route {...props}/>
}

const mapStateToProps = state => ({
  authorizationData: state.authorization
})

export default connect(mapStateToProps)(UnauthorizedRoute)
