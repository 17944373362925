import { call, put, takeLatest } from 'redux-saga/effects'

import { AuthorizationTypes } from '../authorization/types'
import { LoginTypes } from './types'

import * as services from './services'

function * login (action) {
  try {
    yield put({ type: LoginTypes.LOGIN_LOAD })
    yield call(services.loginUser, action.payload)
    yield put({ type: LoginTypes.LOGIN_SUCCESS })
    yield put({ type: AuthorizationTypes.AUTHORIZATION_SET })
  } catch (err) {
    yield put({ type: LoginTypes.LOGIN_FAILED, error: err })
  }
}

export default function * loginSaga () {
  yield takeLatest(LoginTypes.LOGIN_SET, login)
}
