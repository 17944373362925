import { Reducer } from 'redux'
import { FunctionState, FunctionTypes } from './types'

const INITAL_STATE: FunctionState = {
  data: undefined,
  isLoading: false,
  error: null,
  success: false
}

const functionsReducer: Reducer<FunctionState> = (state = INITAL_STATE, action) => {
  switch (action.type) {
    case FunctionTypes.FUNCTION_GET:
      return {
        ...state
      }

    case FunctionTypes.FUNCTION_GET_LOAD:
      return {
        ...state,
        isLoading: true,
        success: false
      }

    case FunctionTypes.FUNCTION_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        success: true
      }

    case FunctionTypes.FUNCTION_GET_FAILED:
      return {
        ...state,
        isLoading: false,
        data: [],
        error: action.error,
        success: false
      }

    default: return state
  }
}

export default functionsReducer
