import { Reducer } from 'redux'
import { FiltersState, FiltersTypes } from './types'

const INITAL_STATE: FiltersState = {
  data: undefined,
  isLoading: false,
  error: null,
  success: false
}

const filtersReducer: Reducer<FiltersState> = (state = INITAL_STATE, action) => {
  switch (action.type) {
    case FiltersTypes.FILTERS_SET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        success: true
      }

    case FiltersTypes.FILTERS_SET_FAILED:
      return {
        ...state,
        isLoading: false,
        data: undefined,
        error: action.error,
        success: false
      }

    case FiltersTypes.FILTER_SET_CLEAN:
      return { ...INITAL_STATE }

    default: return state
  }
}

export default filtersReducer
