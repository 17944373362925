// eslint-disable-next-line no-use-before-define
import React from 'react'
import { Modal, Typography } from '@material-ui/core'

interface Props {
  title: string,
  body: string,
  icon: any,
  iconColor: string,
  open: boolean,
  actions: any[]
}

function ModalInformation ({
  title,
  body,
  icon,
  iconColor,
  open,
  actions
}: Props) {
  return (
    <Modal
      open={open}
      onClose={() => { }}
    >
      <div className="ModalInformation">
        <div className="ModalInformation__Content" style={{ textAlign: 'center' }}>
          <div className={`ModalInformation__Content__Icon__${iconColor}`}>
            {icon}
          </div>
          <Typography variant="h3">
            {title}
          </Typography>
          <Typography variant="h4">
            {body}
          </Typography>
        </div>
        <div style={{ width: '100%' }}>
          {actions}
        </div>
      </div>
    </Modal>
  )
}
export default ModalInformation
