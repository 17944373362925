/**
 * ACTION TYPES
 */
export enum CompaniesTypes {
  COMPANIES_GET = 'COMPANIES_GET',
  COMPANIES_GET_LOAD = 'COMPANIES_GET_LOAD',
  COMPANIES_GET_SUCCESS = 'COMPANIES_GET_SUCCESS',
  COMPANIES_GET_FAILED = 'COMPANIES_GET_FAILED',
  REQUEST_COHORT = 'REQUEST_COHORT',
  REQUEST_COHORT_LOAD = 'REQUEST_COHORT_LOAD',
  REQUEST_COHORT_SUCCESS = 'REQUEST_COHORT_SUCCESS',
  REQUEST_COHORT_FAILED = 'REQUEST_COHORT_FAILED'
}

/**
 * Data types
 */
export interface CompaniesI {
  id: string
  socialName: string
}

export interface RequestCohortI {
  message: string
  companyId?: string
}

/**
 * State type
 */
export interface CompaniesState {
  data?: CompaniesI[],
  error: {
    message: string
  } | null,
  isLoading: boolean,
  success: boolean,
  requestCohort: {
    error: {
      message: string
    } | null,
    isLoading: boolean,
    success: boolean
  }
}
