import { action } from 'typesafe-actions'
import { CompaniesTypes, CompaniesI, RequestCohortI } from './types'

export const companiesSet = () => action(CompaniesTypes.COMPANIES_GET)

export const companiesLoad = () => action(CompaniesTypes.COMPANIES_GET_LOAD)

export const companiesSuccess = (data: CompaniesI[]) =>
  action(CompaniesTypes.COMPANIES_GET_SUCCESS, data)

export const companiesFailed = () => action(CompaniesTypes.COMPANIES_GET_FAILED)

export const requestCohort = (data: RequestCohortI) => action(CompaniesTypes.REQUEST_COHORT, data)

export const requestCohortLoad = () => action(CompaniesTypes.REQUEST_COHORT_LOAD)

export const requestCohortSuccess = () =>
  action(CompaniesTypes.REQUEST_COHORT_SUCCESS)

export const requestCohortFailed = () => action(CompaniesTypes.REQUEST_COHORT_FAILED)
