/**
 * ACTION TYPES
 */
export enum LocationTypes {
  LOCATION_GET = 'LOCATION_GET',
  LOCATION_GET_LOAD = 'LOCATION_GET_LOAD',
  LOCATION_GET_SUCCESS = 'LOCATION_GET_SUCCESS',
  LOCATION_GET_FAILED = 'LOCATION_GET_FAILED'
}

/**
 * Data types
 */
export interface LocationI {
  state: string
  cities: string[]
}

/**
 * State type
 */
export interface LocationState {
  data?: LocationI[],
  error: {
    message: string
  } | null,
  isLoading: boolean,
  success: boolean
}
