// import Axios from 'axios'
import VojoApi from '../../services/VojoApi'
import handleErrors from '../../utils/middlewares/handleErrors'
import { getJobRoles } from './queries'

export const getFunctions = async (functionId?: string) => {
  const vojoApi = new VojoApi()
  vojoApi.createInstance()
  const { data } = await vojoApi.instance.get('/graphql', { params: { query: getJobRoles(functionId) } })
  if (data.errors && data.errors?.length > 0) {
    handleErrors(data.errors[0].extensions.exception)
    return data.errors
  }
  return data.data.getJobRoles
}
