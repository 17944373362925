import { AbstractComponentHandler, ButtonComponentHandler } from './handlers'

import { ViewElement } from './ViewElement'

export class ComponentProcessor {
  public processComponent (component: ViewElement) : any {
    const correctHandler = this.handlers.find(handler => handler.accepts(component))
    if (correctHandler) return correctHandler.renderComponent(component)
  }

  constructor (private handlers: AbstractComponentHandler[]) {

  }

  getHandlers (): AbstractComponentHandler[] {
    return this.handlers
  }

  // const pageProcessor = PageProcessor.buildProcessor()
  // pageProcessor.processComponent(component)

  public static buildProcessor (handlers: AbstractComponentHandler[] = []): ComponentProcessor {
    if (!handlers.length) {
      handlers = [
        // colocar quais são os tratadores default
        new ButtonComponentHandler()
      ]
    }
    return new ComponentProcessor(handlers)
  }
}
