export enum EGenderCode {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  OTHER = 'OTHER',
  NONE = 'NONE'
}

export enum EGenderDescription {
  FEMALE = 'Feminino',
  MALE = 'Masculino',
  OTHER = 'Outro',
  NONE = 'Prefiro não informar'
}