export enum AuthorizationTypes {
  AUTHORIZATION_SET = 'AUTHORIZATION_SET',
  AUTHORIZATION_UNSET = 'AUTHORIZATION_UNSET',
  AUTHORIZATION_UNSET_SUCCESS = 'AUTHORIZATION_UNSET_SUCCESS',
  AUTHORIZATION_LOAD = 'AUTHORIZATION_LOAD',
  AUTHORIZATION_SUCCESS = 'AUTHORIZATION_SUCCESS',
  AUTHORIZATION_FAILED = 'AUTHORIZATION_FAILED'
}

export interface AuthorizationI {
  id: string,
  companyId: string,
  loginType: string,
  role: string[]
  firstName: string,
  lastName: string,
  email: string,
  cellphone: string
}

export interface AuthorizationI {
  id: string,
  companyId: string,
  companySocialName: string,
  loginType: string,
  role: string[]
  firstName: string,
  lastName: string,
  email: string,
  cellphone: string
}

export interface AuthorizationState {
  error: {
    message: string
  } | null,
  isLoading: boolean,
  success: boolean,
  data?: AuthorizationI,
  isAuth: boolean
}
