const formatLocationLabel = (location) => {
  if (location?.cities && location?.states) {
    const { cities, states } = location
    if (cities?.length > 1) return [`${states?.length} estado e ${cities?.length} cidades`]
    return [states[0], cities[0]]
  }
  return ['']
}

export default formatLocationLabel
