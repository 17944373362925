/* eslint-disable react/jsx-key */
// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import * as fileSaver from 'file-saver'
import { Button, Typography } from '@material-ui/core'
import { gql, useLazyQuery } from '@apollo/client'
import { CohortI, CohortStatusChangeI } from '../../redux/cohorts/types'
import * as CohortsActions from '../../redux/cohorts/actions'
import Label from '../Label'
import StatusTag from '../StatusTag'
import { AccountBalanceSharp, FilterList, LocationOn } from '@material-ui/icons'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import ModalAction from '../../components/ModalAction'
import formatLocationLabel from '../../utils/formatLocationLabel'
import { ECohortStatus } from '../../utils/enums/cohort-status.enum'
import { FormInput } from '@mindlab-org/component-library'
import { defaultInputState } from '../../utils/forms/formDefaultStates'
import ModalDetail from '../ModalDetail'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import ModalInformation from '../ModalInformation'
import { GET_COHORT_DETAILS } from '../../redux/cohorts/queries'
import VojoApollo from '../../services/VojoApollo'
import VojoApi from '../../services/VojoApi'
import { WEPAYOUT_VALIDATION_URL } from '../../routes/routes'

interface StateProps {
  cohort: CohortI,
  userRole: string,
  selectedCohortStatus: ECohortStatus[]
}

interface DispatchProps {
  cohortChangeStatusSet(data: CohortStatusChangeI): void,
  cohortSet(data): void
}

interface StateFormI {
  errorMessage: string
  isValid: boolean
  maskedValue: string
  showError: boolean
  value: string
}

type Props = StateProps & DispatchProps

const integrationBodyMessage = {
  default: 'Ao validar contas você envia as informações dos usuários ao wepayout, para verificação dos dados bancários.',
  error: 'Erro ao validar contas'
}


const GENERATE_CSV = gql`
query GenerateCSV($id: String!) {
    cohortToCsv(id: $id)
}
`

const defaultAvailableActions = {
  download: false,
  waiting: false,
  denied: false,
  accepted: false,
  activated: false,
  send: false
}

function CohortCard({
  cohort,
  cohortChangeStatusSet,
  cohortSet,
  selectedCohortStatus,
  userRole
}: Props) {
  const [modalAcceptOpen, setModalAcceptOpen] = useState(false)
  const [modalAcceptIntegration, setModalIntegration] = useState(false)
  const [errorModal, setErrorModal] = useState(false)
  const [modalActivateOpen, setModalActivateOpen] = useState(false)
  const [modalDeniedOpen, setModalDeniedOpen] = useState(false)
  const [modalSendOpen, setModalSendOpen] = useState(false)
  const [modalDetailsOpen, setModalDetailsOpen] = useState(false)
  const [forceRefresh, setForceRefresh] = useState(false)
  const [availableActions, setAvailableActions] = useState(defaultAvailableActions)
  const [modalInformationActivatedOpen, setModalInformationActivatedOpen] = useState(false)
  const [modalInformationAcceptedOpen, setModalInformationAcceptedOpen] = useState(false)
  const [modalInformationDeniedOpen, setModalInformationDeniedOpen] = useState(false)
  const [modalInformationSentOpen, setModalInformationSentOpen] = useState(false)
  const [stateForm, setStateForm] = useState({ ...defaultInputState })
  const [cohortDetails, setCohortDetails] = useState({})
  const [statesAndCities, setStatesAndCities] = useState([] as any[])
  const [validateError, setValidateResponse] = useState()

  const [fetchCohortFilters, { loading, data }] = useLazyQuery(
    GET_COHORT_DETAILS,
    {
      client: VojoApollo
    }
  )

  const integrationBodyMessage = {
    default: 'Ao validar contas você envia as informações dos usuários ao wepayout, para verificação dos dados bancários.',
    error: validateError
  }

  useEffect(() => {
    if (data?.getCohortDetails) {
      let getCohortDetails = { ...data.getCohortDetails }
      const { states, cities } = getCohortDetails
      getCohortDetails = { ...getCohortDetails, writable: true }
      for (const key of Object.keys(getCohortDetails)) {
        if (typeof getCohortDetails[key] === 'boolean') {
          getCohortDetails[key] ? (getCohortDetails[key] as string) = 'Sim' : (getCohortDetails[key] as string) = 'Não'
        }
        if (getCohortDetails[key] === null) delete getCohortDetails[key]
        if (Array.isArray(getCohortDetails[key]) && !getCohortDetails[key].length) delete getCohortDetails[key]
      }
      delete getCohortDetails.writable
      delete getCohortDetails.states
      delete getCohortDetails.cities
      delete getCohortDetails.__typename
      setCohortDetails(getCohortDetails)
      setStatesAndCities([states, cities])
      setModalDetailsOpen(true)
    }
  }, [data, forceRefresh])

  const handleModalDetailsOpen = (filterId) => {
    fetchCohortFilters({ variables: { filterId } })
    setForceRefresh(!forceRefresh)
  }

  const handlerModalDetailsClose = () => {
    setCohortDetails({})
    setStatesAndCities([])
    setModalDetailsOpen(false)
  }

  useEffect(() => {
    getAvailableActions()
    // eslint-disable-next-line
  }, [cohort])

  const getAvailableActions = () => {
    const status = cohort?.status && cohort?.status[cohort?.status.length - 1].name
    switch (userRole) {
      case 'OPERATOR':
        switch (status) {
          case ECohortStatus.WAITING:
            setAvailableActions({ ...defaultAvailableActions, download: true })
            break
          case ECohortStatus.PENDING:
            setAvailableActions({ ...defaultAvailableActions, download: true, send: true })
            break
          case ECohortStatus.ACCEPTED:
            setAvailableActions({ ...defaultAvailableActions, download: true })
            break
          case ECohortStatus.ACTIVATED:
            setAvailableActions({ ...defaultAvailableActions, download: true })
            break
          case ECohortStatus.DENIED:
            setAvailableActions({ ...defaultAvailableActions, denied: true })
            break
          case ECohortStatus.VALIDATING:
            setAvailableActions({ ...defaultAvailableActions, download: true, send: true })
            break
          default:
            // Medida provisória até ter validação de operator/manager
            setAvailableActions({ ...defaultAvailableActions, download: true })
            break
        }
        break
      case 'MANAGER':
        switch (status) {
          case ECohortStatus.WAITING:
            setAvailableActions({ ...defaultAvailableActions, waiting: true })
            break
          case ECohortStatus.PENDING:
            setAvailableActions({ ...defaultAvailableActions, download: true, send: true })
            break
          case ECohortStatus.ACCEPTED:
            setAvailableActions({ ...defaultAvailableActions, accepted: true })
            break
          case ECohortStatus.ACTIVATED:
            setAvailableActions({ ...defaultAvailableActions, activated: true })
            break
          case ECohortStatus.DENIED:
            setAvailableActions({ ...defaultAvailableActions, denied: true })
            break
          default:
            // Medida provisória até ter validação de operator/manager
            setAvailableActions({ ...defaultAvailableActions, download: true })
            break
        }
        break
    }
  }

  const [runQuery] = useLazyQuery(GENERATE_CSV, {
    onCompleted: data => {
      const blob = new Blob(['\ufeff', data.cohortToCsv], { type: 'text/csv;charset=UTF-8' })
      fileSaver.saveAs(blob, `${cohort.slug}.csv`)
    }
  })

  const generateCsv = (id) => {
    runQuery({ variables: { id } })
  }

  const wepayoutIntegration = async (id) => {
    try {

      await new VojoApi().instance.post(WEPAYOUT_VALIDATION_URL(id))

      setModalIntegration(false)
    } catch (errResponse) {
      setErrorModal(true)
      setValidateResponse(errResponse?.response.data.error.message)
    }
  }

  const resetModal = () => {
    setErrorModal(false)
    setModalIntegration(false)
  }

  const handleChangeStatus = async (status: ECohortStatus) => {
    await cohortChangeStatusSet({ cohortId: cohort.id, status: status })
    handleModalInformation(status)
  }

  const handleModalInformation = (status: ECohortStatus) => {
    switch (status) {
      case 'ACCEPTED':
        setModalAcceptOpen(false)
        setModalInformationAcceptedOpen(true)
        break
      case 'ACTIVATED':
        setModalActivateOpen(false)
        setModalInformationActivatedOpen(true)
        break
      case 'DENIED':
        setModalDeniedOpen(false)
        setModalInformationDeniedOpen(true)
        break
      case 'WAITING':
        setModalSendOpen(false)
        setModalInformationSentOpen(true)
        break
      default:
        cohortSet({ status: selectedCohortStatus })
        break
    }
  }

  const renderModalAccept = () => (
    <ModalAction
      open={modalAcceptOpen}
      title={'Aceitar CANDIDATOS?'}
      body={<Typography variant="h4">
        {'Ao aceitar você automaticamente aprova os candidatos selecionados pela nossa equipe.'}
      </Typography>}
      actions={[
        <Button
          key={1}
          style={{ color: '#B8B5C6' }}
          color="default"
          onClick={() => setModalAcceptOpen(false)}>
          {'CANCELAR'}
        </Button>,
        <Button
          key={2}
          color="primary"
          onClick={() => handleChangeStatus(ECohortStatus.ACCEPTED)}>
          {'ACEITAR'}
        </Button>
      ]}
    />
  )

  const renderModalIntegration = () => (
    <ModalAction
      open={modalAcceptIntegration}
      title={'Validar as Contas Bancárias'}
      body={<Typography variant="h4">
        {errorModal ? integrationBodyMessage.error : integrationBodyMessage.default}
      </Typography>}
      actions={[
        <Button
          key={1}
          style={{ color: '#B8B5C6' }}
          color="default"
          onClick={resetModal}>
          CANCELAR
        </Button>,
        !errorModal &&
        <Button
          key={2}
          color="primary"
          onClick={() => wepayoutIntegration(cohort?.id)}>
          ENVIAR
        </Button>
      ]}
    />
  )

  const renderModalActivate = () => (
    <ModalAction
      open={modalActivateOpen}
      title="Ativar CANDIDATOS?"
      body={<Typography variant="h4">
        {'Ao ativar você automaticamente aceita que o VOJO acione os candidatos selecionados e os direcionem para começarem a trabalhar.'}
      </Typography>}
      actions={[
        <Button
          key={1}
          style={{ color: '#B8B5C6' }}
          color="default"
          onClick={() => setModalActivateOpen(false)}>
          {'CANCELAR'}
        </Button>,
        <Button
          key={2}
          color="primary"
          onClick={() => handleChangeStatus(ECohortStatus.ACTIVATED)}>
          {'ATIVAR'}
        </Button>
      ]}
    />
  )

  const renderModalSend = () => (
    <ModalAction
      open={modalSendOpen}
      title="Enviar CANDIDATOS?"
      body={<Typography variant="h4">
        {'Ao enviar o cohort você disponibiliza para a empresa os candidatos para que ela aceite ou negue!'}
      </Typography>}
      actions={[
        <Button
          key={1}
          style={{ color: '#B8B5C6' }}
          color="default"
          onClick={() => setModalSendOpen(false)}>
          {'CANCELAR'}
        </Button>,
        <Button
          key={2}
          color="primary"
          onClick={() => handleChangeStatus(ECohortStatus.WAITING)}>
          {'ENVIAR'}
        </Button>
      ]}
    />
  )

  function validateFeedback(cohort: CohortStatusChangeI) {
    if (stateForm.isValid) {
      cohortChangeStatusSet(cohort)
      handleModalInformation(cohort.status)
    } else {
      setStateForm({ ...stateForm, showError: true })
    }
  }

  function setFeedback(state: StateFormI) {
    if (state.value.trim() === '') {
      setStateForm({ ...stateForm, showError: true, isValid: false })
    } else {
      setStateForm({ ...stateForm, showError: false, isValid: true, value: state.value })
    }
    return state
  }

  const cohortDateTime = () => {
    const lastStatusTime = cohort?.status[cohort?.status.length - 1].changedAt
    const lastStatusMoment = moment(lastStatusTime)
    const formatedMoment = lastStatusMoment.format('DD/MM/YYYY - HH:mm')
    return formatedMoment
  }

  const showCorhortDateTime = () => {
    const status = cohort?.status[cohort?.status.length - 1].name
    if (
      status === ECohortStatus.ACCEPTED ||
      status === ECohortStatus.ACTIVATED ||
      status === ECohortStatus.DENIED
    ) {
      return true
    }
    return false
  }

  const renderModalDenied = () => {
    let feedback = stateForm
    return (
      <ModalAction
        open={modalDeniedOpen}
        title="Negar CANDIDATOS?"
        body={
          <div>
            <Typography variant="h4">
              {'Ao negar você automaticamente rejeita os candidatos selecionados pela nossa equipe.'}
            </Typography>
            <Typography variant="h4" style={{ fontWeight: 'bold' }}>
              {'Não vai utilizar os candidatos?'}
            </Typography>
            <Typography variant="h4">
              {'Dê seu  feedback e nos ajude a melhorar o VOJO:'}
            </Typography>
            <FormInput
              id="feedback"
              isRequired
              label="Seu feedback aqui"
              name="feedback"
              onInputBlur={(state) => {
                feedback = setFeedback(state)
              }}
              onInputChange={(state) => {
                feedback = setFeedback(state)
              }}
              placeholder="Seu feedback aqui"
              type="text"
              showError={stateForm.showError}
              value={feedback?.value}
            />
          </div>
        }
        actions={[
          <Button
            key={1}
            style={{ color: '#B8B5C6' }}
            color="default"
            onClick={() => setModalDeniedOpen(false)}>
            {'CANCELAR'}
          </Button>,
          <Button
            key={2}
            style={{ color: '#ff4d4d' }}
            color="default"
            onClick={() => validateFeedback({ cohortId: cohort.id, status: ECohortStatus.DENIED, feedback: feedback?.value })}>
            {'NEGAR'}
          </Button>
        ]}
      />)
  }

  const renderModalDetails = () => {
    if (!modalDetailsOpen) return <div></div>
    return <> <ModalDetail
      statesAndCities={statesAndCities}
      cohortDetails={cohortDetails}
      onClickClose={() => handlerModalDetailsClose()}
      candidates={cohort.total}
      jobRole={cohort.jobRole.title}
      company={cohort.company.socialName}
      slug={cohort.slug}
    />
    </>
  }

  const renderModalInformationAccepted = () => {
    return (
      <ModalInformation
        open={modalInformationAcceptedOpen}
        title={'CANDIDATOS aceitos!'}
        body={'Os candidatos foram aceitos com sucesso para a sua empresa e já estão disponíveis para serem ativados!'}
        icon={<CheckCircleOutlineIcon style={{ fontSize: 100 }} />}
        iconColor={'Primary'}
        actions={[<div style={{ width: '100%' }}>
          <Button
            style={{ width: '100%' }}
            color="primary"
            variant='contained'
            onClick={() => {
              cohortSet({ status: selectedCohortStatus })
              setModalInformationAcceptedOpen(false)
            }}
          >
            {'OK!'}
          </Button>
        </div>]}
      />
    )
  }

  const renderModalInformationActivated = () => (
    <ModalInformation
      title={'CANDIDATOS ativados!'}
      body={'Os candidatos foram acionados pelo VOJO para começarem a trabalhar!'}
      icon={<CheckCircleOutlineIcon style={{ fontSize: 100 }} />}
      iconColor={'Primary'}
      open={modalInformationActivatedOpen}
      actions={[<div style={{ width: '100%' }}>
        <Button
          style={{ width: '100%' }}
          color="primary"
          variant='contained'
          onClick={() => {
            cohortSet({ status: selectedCohortStatus })
            setModalInformationActivatedOpen(false)
          }}
        >
          {'OK!'}
        </Button>
      </div>]}
    />
  )

  const renderModalInformationDenied = () => (
    <ModalInformation
      title={'CANDIDATOS negados!'}
      body={'Os candidatos foram negados e voltaram para a Lista de Espera do VOJO!'}
      icon={<CancelOutlinedIcon style={{ fontSize: 100 }} />}
      iconColor={'Secondary'}
      open={modalInformationDeniedOpen}
      actions={[<div style={{ width: '100%' }}>
        <Button
          style={{ width: '100%' }}
          color="primary"
          variant='contained'
          onClick={() => {
            cohortSet({ status: selectedCohortStatus })
            setModalInformationDeniedOpen(false)
          }
          }
        >
          {'OK!'}
        </Button>
      </div>]}
    />
  )

  const renderModalInformationSent = () => (
    <ModalInformation
      title={'CANDIDATOS enviados!'}
      body={'Os candidatos foram enviados com sucesso para a empresa!'}
      icon={<CheckCircleOutlineIcon style={{ fontSize: 100 }} />}
      iconColor={'Primary'}
      open={modalInformationSentOpen}
      actions={[<div style={{ width: '100%' }}>
        <Button
          style={{ width: '100%' }}
          color="primary"
          variant='contained'
          onClick={() => {
            cohortSet({ status: selectedCohortStatus })
            setModalInformationSentOpen(false)
          }
          }
        >
          {'OK!'}
        </Button>
      </div>]}
    />
  )

  /**
   * @description Recebe o nome social da Empresa e seu Slug, e retorna um título formatado com nome, data e hora.
   * @returns {String} O título do card do cohort formatado. 
   */
  const formatsTitleCohortCard = () => {
    const cohortCompanySocialName = cohort?.company?.socialName
    const [date, hour] = cohort.slug.split('_').slice(-2)
    const formattedDate = date.split('-').join('/')
    return `${cohortCompanySocialName} - ${formattedDate} ${hour}`
  }


  return (
    <div
      className="CohortCard"
      id={`cohort-card-${cohort?.id}`}
    >
      <div className="CohortCard__Content">
        <div className="CohortCard__Content__Title">
          <Typography
            variant="h2">
            {formatsTitleCohortCard()}
          </Typography>
        </div>
        <div className="View__Subtitle">
          <Typography
            variant="h3">
            {cohort?.jobRole?.title}
          </Typography>
        </div>
        <div className="CohortCard__Content__Details">
          <div className="CohortCard__Content__Details__Info">
            <div className="CohortCard__Content__Details__Info__Tag">
              {
                cohort?.status &&
                <StatusTag
                  key={cohort?.id}
                  text={cohort?.status[cohort?.status.length - 1].name.toString()} />
              }
              {
                showCorhortDateTime() && (
                  <div className="CohortCard__Content__Details__Date">
                    <AccessTimeIcon fontSize="small" />
                    <Typography>
                      {cohortDateTime()}
                    </Typography>
                  </div>
                )
              }
            </div>
            <div className="CohortCard__Content__Details__Labels">
              <Label texts={[`${cohort?.totalFilters?.toString()} filtros aplicados`]} icon={<FilterList style={{ color: '#9E9E9E' }} />} onClick={() => handleModalDetailsOpen(cohort.filterId)} />
              <Label texts={[cohort?.company?.socialName]} icon={<AccountBalanceSharp style={{ color: '#9E9E9E' }} />} onClick={() => handleModalDetailsOpen(cohort.filterId)} />
              <Label texts={formatLocationLabel(cohort?.location)} icon={<LocationOn style={{ color: '#9E9E9E' }} />} onClick={() => handleModalDetailsOpen(cohort.filterId)} />
              <span onClick={() => handleModalDetailsOpen(cohort.filterId)}>Ver detalhes</span>
            </div>
          </div>
          <div className="CohortCard__Content__Details__Total">
            <Typography
              variant="h2">
              {cohort?.total}
            </Typography>
            <Typography
              variant="h4">
              candidatos
            </Typography>
          </div>
        </div>
        <div className="CohortCard__Content__Actions">
          {cohort?.status[0].name == ECohortStatus.PENDING &&
            <Button
              className="CohortCard__Content__Actions__Button"
              color="primary"
              variant='contained'
              onClick={() => setModalIntegration(true)}>
              VALIDAR CONTAS
            </Button>}
          {availableActions.download && <Button
            className="CohortCard__Content__Actions__Button"
            color="primary"
            variant='contained'
            onClick={() => generateCsv(cohort?.id)}>
            BAIXAR
          </Button>}
          {availableActions.waiting && <Button
            className="CohortCard__Content__Actions__Button"
            style={{ color: '#ff4d4d' }}
            color="default"
            onClick={() => setModalDeniedOpen(true)}>
            NEGAR
          </Button>}
          {availableActions.waiting &&
            <Button
              className="CohortCard__Content__Actions__Button"
              color="primary"
              variant='contained'
              onClick={() => setModalAcceptOpen(true)}>
              ACEITAR
            </Button>}
          {availableActions.accepted &&
            <Button
              className="CohortCard__Content__Actions__Button"
              color="primary"
              variant='contained'
              onClick={() => setModalActivateOpen(true)}>
              ATIVAR
            </Button>}
          {availableActions.send &&
            <Button
              className="CohortCard__Content__Actions__Button"
              color="default"
              style={{ backgroundColor: '#75BA1D', color: '#FFFFFF' }}
              variant='contained'
              onClick={() => setModalSendOpen(true)}>
              ENVIAR
            </Button>}
          {availableActions.denied &&
            <Typography className="CohortCard__Content__Actions__Label">
              Candidatos negados
            </Typography>
          }
          {availableActions.activated &&
            <Typography className="CohortCard__Content__Actions__Label">
              Condidatos ativados pelo Vojo
            </Typography>
          }
        </div>
      </div>
      {renderModalAccept()}
      {renderModalActivate()}
      {renderModalDenied()}
      {renderModalSend()}
      {renderModalDetails()}
      {renderModalInformationActivated()}
      {renderModalInformationAccepted()}
      {renderModalInformationDenied()}
      {renderModalInformationSent()}
      {renderModalIntegration()}
    </div>)
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => bindActionCreators(
  { ...CohortsActions }, dispatch
)

export default connect(null, mapDispatchToProps)(CohortCard)
