import { EDaysOfWork, EDaysOfWorkCode, EMei, EMeiDescription } from '../redux/cohorts/types'
import { EAssessmentStatus } from './enums/assessment-status.enum'

const mapBooleanFilter = (field?: boolean) => {
  if (field === undefined) return 'Indiferente'
  if (field) return 'Sim'
  return 'Não'
}

const mapAvailablePeriod = (availablePeriod: string[]) => {
  return availablePeriod
}

const mapDegree = (degree: string[]) => {
  return degree
}

const mapEmploymentType = (employmentType: string[]) => {
  return employmentType
}

const mapGenders = (genders: string[]) => {
  return genders
}

const mapMeiEnum = (meiEnum: EMei) => {
  return Object.values(meiEnum).map(v => EMeiDescription[v])[0]
}

const mapFormStatusJames = (formStatusJames: boolean[]) => {
  return formStatusJames.map(f => mapBooleanFilter(f))
}

const mapDaysOfWork = (daysOfWork: EDaysOfWorkCode[]) => {
  return daysOfWork.map(dayOfWork => {
    if (EDaysOfWork[dayOfWork]) return EDaysOfWork[dayOfWork]
    return dayOfWork
  })
}

const mapAssessmentStatusJames = (assessmentStatusJames: EAssessmentStatus[]) => {
  return assessmentStatusJames
}

const mapBankAccountStatus = (bankAccountStatus: string[]) => {
  return bankAccountStatus.map(bankAccount => {
    if (bankAccount === 'VALID_DATA') return 'Aprovado'
    if (bankAccount === 'INVALID_DATA') return 'Reprovado'
    return bankAccount
  })
}

const mapBackgroundCheckStatus = (backgroundCheckStatus: string) => {
  return backgroundCheckStatus
}

const mapSmartphone = (smartphone: string[]) => {
  return smartphone.map(s => s)
}

const mapTransportation = (transportation: string[]) => {
  return transportation.map(t => t)
}

const cohortFilterFields = {
  adulthood: 'Maior de 18 anos?',
  applicationStatus: 'Status da candidatura',
  assessmentStatus: 'Status da avaliação',
  availablePeriod: 'Em quais período do dia tem disponibilidade para trabalhar? ',
  availableSpace: 'Possui espaço físico adequado para desempenhar a função?',
  bank: 'Possui conta bancária em seu nome?',
  cnh: 'CNH',
  courses: 'Já fez algum curso que ajude a realizar essa função? ',
  creci: 'CRECI',
  degree: 'Grau de Escolaridade',
  disability: 'Possui alguma deficiência?',
  employmentType: 'Tem disponibilidade para trabalhar em quais modelos de trabalho?',
  experience: 'Possui experiência?',
  genders: 'Gênero',
  mei: 'Possui MEI?',
  MEIEnum: 'MEIEnum',
  assessmentStatusJames: 'Status da avaliação James',
  formStatusJames: 'Status do Formulario James',
  daysOfWork: 'Dias disponiveis',
  bankAccountStatus: 'Conta Bancaria Validada?',
  backgroundCheckStatus: 'Background Check',
  pis: 'Possui PIS válido?',
  proofAddress: 'Possui comprovante de residência em seu nome?',
  smartphone: 'Smartphone',
  tool: 'Possui ferramentas adequadas para desempenhar a função?',
  transportation: 'Tipo de veículo disponível para função?',
  weekendAvailability: 'Possui disponibilidade para trabalhar final de semana?'
}

const cohortFilterValues = {
  adulthood: mapBooleanFilter,
  availablePeriod: mapAvailablePeriod,
  availableSpace: mapBooleanFilter,
  bank: mapBooleanFilter,
  cnh: mapBooleanFilter,
  courses: mapBooleanFilter,
  creci: mapBooleanFilter,
  degree: mapDegree,
  disability: mapBooleanFilter,
  employmentType: mapEmploymentType,
  experience: mapBooleanFilter,
  genders: mapGenders,
  mei: mapBooleanFilter,
  MEIEnum: mapMeiEnum,
  formStatusJames: mapFormStatusJames,
  daysOfWork: mapDaysOfWork,
  assessmentStatusJames: mapAssessmentStatusJames,
  bankAccountStatus: mapBankAccountStatus,
  backgroundCheckStatus: mapBackgroundCheckStatus,
  pis: mapBooleanFilter,
  proofAddress: mapBooleanFilter,
  smartphone: mapSmartphone,
  tool: mapBooleanFilter,
  transportation: mapTransportation,
  weekendAvailability: mapBooleanFilter
}

export const mapCohortFilterField = (field: string) => {
  if (cohortFilterFields[field]) return cohortFilterFields[field]
  return field
}

export const mapCohortFilterValue = (field: string, value: any) => {
  if (cohortFilterValues[field] && value !== undefined) return cohortFilterValues[field](value)
  return value
}

export const mapCohortFilter = (field: string, values: any) => {
  const key = mapCohortFilterField(field)
  const value = mapCohortFilterValue(field, values)
  return { label: mapCohortFilterField(key), value: mapCohortFilterValue(field, value) }
}
