import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { ArrowBackIos } from '@material-ui/icons'

interface OwnProps {
  onClickBack?(): void
  onClickCancel?(): void
  subtitle?: string
  title: string
}

const Header = (
  {
    onClickBack,
    onClickCancel,
    subtitle,
    title
  }: OwnProps
) => {
  return (
    <div className="Header">
      <div className="Header__Container">
        <div className="Header__Block">
          <div
            className="Header__Back"
            onClick={onClickBack}>
            <ArrowBackIos
              className="Header__Back__Icon"/>
            <Typography
              align="center"
              className="Header__Back__Text"
              variant="h3">
              {'VOLTAR'}
            </Typography>
          </div>
        </div>
        <div className="Header__Block">
          <Typography
            align="center"
            className="Header__Title"
            variant="h1">
            {title && title.toUpperCase()}
          </Typography>
          <Typography
            align="center"
            className="Header__Subtitle"
            variant="h1">
            {subtitle && subtitle.toUpperCase()}
          </Typography>
        </div>
        <div className="Header__Block">
          <Button
            className="Header__Button"
            color="inherit"
            onClick={onClickCancel}>
            CANCELAR
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Header
