// import Axios from 'axios'
import VojoApi from '../../services/VojoApi'
import { findCompanies } from './queries'
import { RequestCohortI } from './types'

export const getCompanies = async () => {
  const vojoApi = new VojoApi()
  vojoApi.createInstance()
  return (await vojoApi.instance.get('/graphql', { params: { query: findCompanies } })).data.data.findCompanies
}

export const requestCohortPost = async (data: RequestCohortI) => {
  try {
    const message = data.message
    const response = await new VojoApi().instance.post(
      `v3/companies/${data.companyId}/request`,
      { message },
      {
        headers:
        { 'X-Custom-Header': 'ProcessThisImmediately' }
      }
    )
    return response
  } catch (errResponse) {
    const error = errResponse.response.data.error
    throw error
  }
}
