import { Reducer } from 'redux'
import { CohortState, CohortTypes, CohortPreviewTypes, CohortSaveTypes, CohortChangeStatusTypes } from './types'

const INITAL_STATE: CohortState = {
  data: undefined,
  previewData: undefined,
  saveData: undefined,
  isLoading: false,
  error: null,
  success: false
}

const cohortsReducer: Reducer<CohortState> = (state = INITAL_STATE, action) => {
  switch (action.type) {
    // COHORT GET
    case CohortTypes.COHORT_GET:
      return {
        ...state
      }

    case CohortTypes.COHORT_GET_LOAD:
      return {
        ...state,
        isLoading: true,
        success: false
      }

    case CohortTypes.COHORT_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        success: true
      }

    case CohortTypes.COHORT_GET_FAILED:
      return {
        ...state,
        isLoading: false,
        data: [],
        error: action.error,
        success: false
      }

    case CohortTypes.COHORT_GET_CLEAR:
      return {
        ...state,
        isLoading: false,
        data: [],
        error: null,
        success: false
      }

    // COHORT PREVIEW
    case CohortPreviewTypes.COHORT_PREVIEW_GET:
      return {
        ...state
      }

    case CohortPreviewTypes.COHORT_PREVIEW_GET_LOAD:
      return {
        ...state,
        isLoading: true,
        success: false
      }

    case CohortPreviewTypes.COHORT_PREVIEW_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        previewData: action.data,
        success: true
      }

    case CohortPreviewTypes.COHORT_PREVIEW_GET_FAILED:
      return {
        ...state,
        isLoading: false,
        saveData: undefined,
        error: action.error,
        success: false
      }

    case CohortPreviewTypes.COHORT_PREVIEW_GET_CLEAR:
      return {
        ...state,
        previewData: {
          filterId: state.previewData?.filterId,
          id: state.previewData?.id,
          jobRole: state.previewData?.jobRole,
          location: state.previewData?.location,
          slug: state.previewData?.slug,
          status: state.previewData?.status,
          company: state.previewData?.company,
          totalFilters: undefined,
          total: undefined
        }
      }

    // COHORT SAVE
    case CohortSaveTypes.COHORT_SAVE_SET:
      return {
        ...state
      }

    case CohortSaveTypes.COHORT_SAVE_SET_LOAD:
      return {
        ...state,
        isLoading: true,
        success: false
      }

    case CohortSaveTypes.COHORT_SAVE_SET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        saveData: action.data,
        success: true
      }

    case CohortSaveTypes.COHORT_SAVE_SET_FAILED:
      return {
        ...state,
        isLoading: false,
        saveData: undefined,
        error: action.error,
        success: false
      }

    case CohortSaveTypes.COHORT_SAVE_SET_CLEAR:
      return {
        ...state,
        isLoading: false,
        saveData: undefined,
        error: null,
        success: false
      }

    // CHANGE STATUS
    case CohortChangeStatusTypes.COHORT_CHANGE_STATUS_SET:
      return {
        ...state
      }

    case CohortChangeStatusTypes.COHORT_CHANGE_STATUS_SET_LOAD:
      return {
        ...state,
        isLoading: true,
        success: false
      }

    case CohortChangeStatusTypes.COHORT_CHANGE_STATUS_SET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        changeStatusData: action.data,
        success: true
      }

    case CohortChangeStatusTypes.COHORT_CHANGE_STATUS_SET_FAILED:
      return {
        ...state,
        isLoading: false,
        changeStatusData: undefined,
        error: action.error,
        success: false
      }

    case CohortChangeStatusTypes.COHORT_CHANGE_STATUS_SET_CLEAR:
      return {
        ...state,
        isLoading: false,
        saveData: undefined,
        error: null,
        success: false
      }

    default: return state
  }
}

export default cohortsReducer
