import { all } from 'redux-saga/effects'

import companiesSaga from './companies/sagas'
import functionsSaga from './functions/sagas'
import cohortsSaga from './cohorts/sagas'
import locationSaga from './location/sagas'
import loginSaga from './login/sagas'
import authorizationSaga from './authorization/sagas'

function * rootSaga () {
  yield all([
    cohortsSaga(),
    companiesSaga(),
    functionsSaga(),
    locationSaga(),
    loginSaga(),
    authorizationSaga()
  ])
}

export default rootSaga

export type RootSaga = ReturnType<typeof rootSaga>
