import { call, put, takeLatest } from 'redux-saga/effects'

import * as services from './services'
import { CompaniesTypes } from './types'

function * loadCompanies () {
  try {
    yield put({ type: CompaniesTypes.COMPANIES_GET_LOAD })
    const data = yield call(services.getCompanies)
    yield put({ type: CompaniesTypes.COMPANIES_GET_SUCCESS, data: data })
  } catch (err) {
    yield put({ type: CompaniesTypes.COMPANIES_GET_FAILED, err })
  }
}

function * requestCohort (action) {
  try {
    yield put({ type: CompaniesTypes.REQUEST_COHORT_LOAD })
    yield call(services.requestCohortPost, action.payload)
    yield put({ type: CompaniesTypes.REQUEST_COHORT_SUCCESS })
  } catch (error) {
    yield put({ type: CompaniesTypes.REQUEST_COHORT_FAILED, error })
  }
}

// This will watch for the actions dispatches
export default function * functionsSaga () {
  yield takeLatest(CompaniesTypes.COMPANIES_GET, loadCompanies)
  yield takeLatest(CompaniesTypes.REQUEST_COHORT, requestCohort)
}
