import axios, { AxiosInstance } from 'axios'

import getAuthToken from '../../utils/Authentication/getAuthToken'

interface VojoApiInterface {
  baseURL: string
  instance: AxiosInstance
  createInstance(): void
  configureHeaders(): Object
}

interface VojoApiHeader {
  'Content-Type': string
  Authorization?: string
}

class VojoApi implements VojoApiInterface {
  baseURL: string
  instance: AxiosInstance

  constructor () {
    this.baseURL = process.env.REACT_APP_BACKEND_REST_API_URL || ''
    this.instance = this.createInstance()
  }

  createInstance = (): AxiosInstance => {
    const headers = this.configureHeaders()

    return axios.create({
      baseURL: this.baseURL,
      headers
    })
  }

  configureHeaders () {
    const authToken = getAuthToken()
    const headers: VojoApiHeader = {
      'Content-Type': 'application/json'
    }
    if (authToken) {
      headers.Authorization = `Bearer ${authToken}`
    }

    return headers
  }
}

export default VojoApi
