import React, { useState } from 'react'

interface OwnProps {
  onClickOption(object),
  optionList: {
    status: string[],
    title: string,
    count: number
  }[]
}

const StatusSelector = (
  {
    onClickOption,
    optionList
  }: OwnProps
) => {
  const [selectedIndex, setselectedIndex] = useState(0)

  const handleSelectOption = (index) => {
    setselectedIndex(index)
    onClickOption(optionList[index])
  }
  return (
    <div className="StatusSelector">
      {
        optionList.map((option, index) => {
          return (
            <div
              key={index}
              className={`StatusSelector__Button${index === selectedIndex ? '--active' : ''}`}
              onClick={() => handleSelectOption(index)}>
              {option.title} {!isNaN(option.count) && `( ${option.count ?? 0} )`}
            </div>
          )
        }
        )
      }
    </div>
  )
}

export default StatusSelector
