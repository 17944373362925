// eslint-disable-next-line no-use-before-define
import React from 'react'
import { mapCohortFilter } from '../../utils/formatCohortFieldAndValues'
import { ModalDetails } from '@mindlab-org/component-library'

interface Props {
  cohortDetails: any,
  candidates: number,
  jobRole: string,
  slug: string,
  company: string,
  statesAndCities: any[],
  onClickClose(): void
}

interface Filter {
  label: string,
  value: string | string[]
}

function ModalDetail ({
  statesAndCities,
  onClickClose,
  company,
  cohortDetails,
  jobRole,
  slug,
  candidates
}: Props) {
  const [states, cities] = statesAndCities

  /**
  * @description transforma um array de objetos em um novo array de objetos com as chaves "label" e "value".
  * @example
  *   Recebe:
  *   [
  *     {
  *       applicationStatus: ["Em progresso"]
  *     },
  *     {
  *       assessmentStatus: ["Aprovado"]
  *     }
  *   ]
  *
  *   Retorna:
  *   [
  *     {
  *       label: "applicationStatus",
  *       value: ["Em progresso"]
  *     },
  *     {
  *       label: "assessmentStatus",
  *       value: ["Aprovado"]
  *     }
  *   ]
  * @returns {Array<Filter>} contendo label e value.
  */

  const createDetailsArray = (): Array<Filter> => {
    const details: Filter[] = []
    Object.keys(cohortDetails)
      .forEach((_key, index) => {
        const field = Object.keys(cohortDetails)[index]
        const values = Object.values(cohortDetails)[index]
        const fieldObject = mapCohortFilter(field, values)
        details.push(fieldObject)
      })
    return details
  }

  return (
    <div className="ModalDetail">
      <ModalDetails
        title={'Detalhes da Base de Candidatos'}
        onClickClose={() => onClickClose()}
        company={company}
        details={createDetailsArray()}
        jobRole={jobRole}
        slug={slug}
        cities={cities}
        states={states}
        cardWidth={'715px'}
        candidates={candidates} />
    </div>

  )
}

export default ModalDetail
