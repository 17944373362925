import gql from 'graphql-tag'

export const FIND_COHORTS = gql`
  query findCohorts(
    $status: [ECohortStatus!]
  ){
    findCohorts (
      status: $status
    ) {
        id,
        jobRole {
          title
        },
        company {
          socialName
        },
        filterId,
        total,
        totalFilters,
        slug,
        location {
            states,
            cities
        },
        status {
            name,
            changedAt
        }
      }
}`

export const COUNT_COHORTS = gql`
  query countCohorts(
    $status: [ECohortStatus!]!
  ){
    countCohorts (
      status: $status
    ) {
      ACCEPTED,
      WAITING,
      PENDING,
      ACTIVATED, 
      DENIED,
      VALIDATING
      }
  }`

export const PREVIEW_COHORT = gql`
  query previewCohort(
  $adulthood: Boolean
  $agePeriod: AgePeriodDto
  $applicationStatus: [EApplicationStatus!]
  $assessmentStatus: [EAssessmentStatus!]
  $availablePeriod: [EAvailablePeriod!]
  $availableSpace: Boolean
  $bank: Boolean
  $cities: [String!]
  $cnh: Boolean
  $companyId: ID
  $courses: Boolean
  $creci: Boolean
  $degree: [EDegreeCode!]
  $disability: Boolean
  $employmentType: [EEmploymentCode!]
  $experience: Boolean
  $genders: [EGenderCode!]
  $meiEnum: EMei
  $daysOfWork: [EDaysOfWorkCode!]
  $bankAccountStatus: [StatusBankAccount!]
  $assessmentStatusJames: [EAssessmentStatus!]
  $formStatusJames: [Boolean!]
  $jobRoleId: ID!
  $mei: Boolean
  $pis: Boolean
  $proofAddress: Boolean
  $placesToWork: [String!]
  $smartphone: [EMobileOS!]
  $states: [EStates!]
  $tool: Boolean
  $totalUsers: Float
  $transportation: [ETransportationCode!]
  $weekendAvailability: Boolean
  $backgroundCheckStatus: EBgcApplicationStatus
) {
  previewCohort(
    filter: {
      adulthood: $adulthood
      agePeriod: $agePeriod
      applicationStatus: $applicationStatus
      assessmentStatus: $assessmentStatus
      availablePeriod: $availablePeriod
      availableSpace: $availableSpace
      bank: $bank
      meiEnum: $meiEnum
      cities: $cities
      cnh: $cnh
      daysOfWork: $daysOfWork
      companyId: $companyId
      courses: $courses
      creci: $creci
      degree: $degree
      disability: $disability
      employmentType: $employmentType
      experience: $experience
      genders: $genders
      jobRoleId: $jobRoleId
      bankAccountStatus: $bankAccountStatus
      mei: $mei
      assessmentStatusJames: $assessmentStatusJames
      formStatusJames: $formStatusJames
      pis: $pis
      proofAddress: $proofAddress
      placesToWork: $placesToWork
      smartphone: $smartphone
      states: $states
      tool: $tool
      totalUsers: $totalUsers
      transportation: $transportation
      weekendAvailability: $weekendAvailability
      backgroundCheckStatus: $backgroundCheckStatus
    }
  ) {
    jobRole {
      title
    }
    company {
      socialName
    }
    total
    totalFilters
    slug
    location {
      states
      cities
    }
  }
}

`

export const SAVE_COHORT = gql`
  query saveCohort(
    $adulthood: Boolean
  $agePeriod: AgePeriodDto
  $applicationStatus: [EApplicationStatus!]
  $assessmentStatus: [EAssessmentStatus!]
  $availablePeriod: [EAvailablePeriod!]
  $availableSpace: Boolean
  $bank: Boolean
  $cities: [String!]
  $cnh: Boolean
  $companyId: ID
  $courses: Boolean
  $creci: Boolean
  $degree: [EDegreeCode!]
  $disability: Boolean
  $employmentType: [EEmploymentCode!]
  $experience: Boolean
  $genders: [EGenderCode!]
  $meiEnum: EMei
  $daysOfWork: [EDaysOfWorkCode!]
  $bankAccountStatus: [StatusBankAccount!]
  $assessmentStatusJames: [EAssessmentStatus!]
  $formStatusJames: [Boolean!]
  $jobRoleId: ID!
  $mei: Boolean
  $pis: Boolean
  $proofAddress: Boolean
  $placesToWork: [String!]
  $smartphone: [EMobileOS!]
  $states: [EStates!]
  $tool: Boolean
  $totalUsers: Float
  $transportation: [ETransportationCode!]
  $weekendAvailability: Boolean
  $backgroundCheckStatus: EBgcApplicationStatus
  ){
    saveCohort (filter: {
      adulthood: $adulthood
      agePeriod: $agePeriod
      applicationStatus: $applicationStatus
      assessmentStatus: $assessmentStatus
      availablePeriod: $availablePeriod
      availableSpace: $availableSpace
      bank: $bank
      meiEnum: $meiEnum
      cities: $cities
      cnh: $cnh
      daysOfWork: $daysOfWork
      companyId: $companyId
      courses: $courses
      creci: $creci
      degree: $degree
      disability: $disability
      employmentType: $employmentType
      experience: $experience
      genders: $genders
      jobRoleId: $jobRoleId
      bankAccountStatus: $bankAccountStatus
      mei: $mei
      assessmentStatusJames: $assessmentStatusJames
      formStatusJames: $formStatusJames
      pis: $pis
      proofAddress: $proofAddress
      placesToWork: $placesToWork
      smartphone: $smartphone
      states: $states
      tool: $tool
      totalUsers: $totalUsers
      transportation: $transportation
      weekendAvailability: $weekendAvailability
      backgroundCheckStatus: $backgroundCheckStatus
    }) {
      jobRole {
        title
      },
      company {
        socialName
      },
      total,
      totalFilters,
      slug,
      location {
          states,
          cities
      },
      status {
        name,
        changedAt
      }
    }
  }
`

export const COHORT_CHANGE_STATUS = gql`
  mutation ChangeStatus($cohortId: String!, $status: ECohortStatus!, $feedback: String) {
        changeStatus(cohortId: $cohortId, status: $status, feedback: $feedback) {
            id,
            jobRole {
                title
            },
            company {
                socialName
            },
            total,
            totalFilters,
            slug,
            location {
                states,
                cities
            },
            status {
                name,
                changedAt
            }
        }
  }
`

export const GET_COHORT_DETAILS = gql`
  query GetCohortDetails($filterId: String!) {
        getCohortDetails(filterId: $filterId) {
            adulthood,
            applicationStatus,
            assessmentStatus,
            availablePeriod,
            availableSpace,
            bank,
            cities,
            cnh,
            courses,
            creci,
            degree,
            disability,
            employmentType,
            experience,
            genders,
            mei,
            pis,
            proofAddress,
            smartphone,
            states,
            tool,
            transportation,
            weekendAvailability,
            meiEnum
            daysOfWork
            assessmentStatusJames
            formStatusJames
            bankAccountStatus
            backgroundCheckStatus
        }  
      }
    `

export const GENERATE_CSV = gql`
  query generateCsv(
    $adulthood: Boolean
  $agePeriod: AgePeriodDto
  $applicationStatus: [EApplicationStatus!]
  $assessmentStatus: [EAssessmentStatus!]
  $availablePeriod: [EAvailablePeriod!]
  $availableSpace: Boolean
  $bank: Boolean
  $cities: [String!]
  $cnh: Boolean
  $companyId: ID
  $courses: Boolean
  $creci: Boolean
  $degree: [EDegreeCode!]
  $disability: Boolean
  $employmentType: [EEmploymentCode!]
  $experience: Boolean
  $genders: [EGenderCode!]
  $meiEnum: EMei
  $daysOfWork: [EDaysOfWorkCode!]
  $bankAccountStatus: [StatusBankAccount!]
  $assessmentStatusJames: [EAssessmentStatus!]
  $formStatusJames: [Boolean!]
  $jobRoleId: ID!
  $mei: Boolean
  $pis: Boolean
  $proofAddress: Boolean
  $placesToWork: [String!]
  $smartphone: [EMobileOS!]
  $states: [EStates!]
  $tool: Boolean
  $totalUsers: Float
  $transportation: [ETransportationCode!]
  $weekendAvailability: Boolean
  $backgroundCheckStatus: EBgcApplicationStatus
  ){
    generateCsv (filter: {
      adulthood: $adulthood
      agePeriod: $agePeriod
      applicationStatus: $applicationStatus
      assessmentStatus: $assessmentStatus
      availablePeriod: $availablePeriod
      availableSpace: $availableSpace
      bank: $bank
      meiEnum: $meiEnum
      cities: $cities
      cnh: $cnh
      daysOfWork: $daysOfWork
      companyId: $companyId
      courses: $courses
      creci: $creci
      degree: $degree
      disability: $disability
      employmentType: $employmentType
      experience: $experience
      genders: $genders
      jobRoleId: $jobRoleId
      bankAccountStatus: $bankAccountStatus
      mei: $mei
      assessmentStatusJames: $assessmentStatusJames
      formStatusJames: $formStatusJames
      pis: $pis
      proofAddress: $proofAddress
      placesToWork: $placesToWork
      smartphone: $smartphone
      states: $states
      tool: $tool
      totalUsers: $totalUsers
      transportation: $transportation
      weekendAvailability: $weekendAvailability
      backgroundCheckStatus: $backgroundCheckStatus
    })
  }
`
