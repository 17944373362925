import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Spinner, FormInput, StickyToast, ModalActions } from '@mindlab-org/component-library'
import { Typography, Button } from '@material-ui/core'
import { FilterList, AccountBalanceSharp, LocationOn, Create } from '@material-ui/icons'
import { ApplicationState } from '../../redux/store'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import * as routes from '../../routes/routes'
import Header from '../../components/Header'
import Label from '../../components/Label'
import * as CohortActions from '../../redux/cohorts/actions'
import * as CompaniesActions from '../../redux/companies/actions'
import * as FiltersActions from '../../redux/filters/actions'
import * as FunctionActions from '../../redux/functions/actions'
import * as LocationActions from '../../redux/location/actions'
import { CohortState } from '../../redux/cohorts/types'
import { CompaniesState } from '../../redux/companies/types'
import { FiltersState } from '../../redux/filters/types'
import { FunctionState } from '../../redux/functions/types'
import { LocationState } from '../../redux/location/types'
import formatLocationLabel from '../../utils/formatLocationLabel'
import { generateStringCsv } from '../../redux/cohorts/services'
import * as fileSaver from 'file-saver'

interface OwnProps extends RouteComponentProps {}

interface StateProps {
  cohorts: CohortState
  companies: CompaniesState
  filters: FiltersState
  functions: FunctionState
  locations: LocationState
}

interface DispatchProps {
// companiesSet(): void
// filtersSet(filters: object): void
// functionSet(id?: string): void
// locationSet(id?: string): void
// cohortPreviewSet(variables: object): void
  cohortSaveSet(filters: object): void
}

type Props = StateProps & DispatchProps & OwnProps

interface State {
  modalError: boolean
  modalOpen: boolean
  preSelectedApplications: number
  selectedApplications: number
}

class PreviewCohort extends Component<Props, State> {
  constructor (props) {
    super(props)
    this.state = {
      modalError: false,
      modalOpen: false,
      preSelectedApplications: this.props.cohorts.previewData?.total || 0,
      selectedApplications: this.props.cohorts.previewData?.total || 0
    }
  }

  componentDidMount () {
    this.checkForStates()
  }

  componentDidUpdate = (prevProps) => {
    if (
      this.props.cohorts.saveData !== prevProps.cohorts.saveData &&
      this.props.cohorts.saveData !== undefined
    ) {
      this.props.history.push(routes.COHORT_LIST)
    }
  }

  checkForStates = () => {
    if (
      !this.props.cohorts.previewData ||
      !this.props.filters.data ||
      !this.props.functions.data
    ) {
      alert('Houve um erro ao carregar as informações, ou não há um cohort filtrado.')
      this.props.history.push(routes.HOMEPAGE)
    }
  }

  redirectToHome = () => {
    this.props.history.push(routes.HOMEPAGE)
  }

  renderSpinner = () => (
    <Spinner spinnerArea="30hv"/>
  )

  generateCohortName = () => {
    const momentDateTime = moment(new Date())
    const dateTime = momentDateTime.format('DD-MM-YYYY_HH:mm')

    const variables = [
      this.props.functions.data?.[0].title.toUpperCase().replace(' ', '_'),
      this.props.cohorts.previewData?.company!.socialName.toUpperCase().replace(' ', '_'),
      dateTime
    ]
    return variables.reduce((prevName, currName, index) => {
      if (index === 0) {
        return `${currName}`
      }
      return `${prevName}_${currName}`
    })
  }

  getRemainingApplication = () => {
    return ((this.props.cohorts.previewData?.total || 0) - this.state.selectedApplications)
  }

  getCompanyFromProps = () => {
    return !this.props.cohorts.previewData?.company
      ? ''
      : this.props.cohorts.previewData?.company.socialName
  }

  getFilterCounterFromProps = () => {
    return !this.props.cohorts.previewData?.totalFilters
      ? ''
      : this.props.cohorts.previewData?.totalFilters
  }

  handlePreSelectedApplicationsChange = (value) => {
    if (parseInt(value) > (this.props.cohorts.previewData?.total || 0)) {
      this.setState({
        modalError: true
      })
    } else {
      this.setState({
        modalError: false,
        preSelectedApplications: parseInt(value)
      })
    }
  }

  handleSelectedApplicationsChange = () => {
    this.setState({
      modalOpen: false,
      selectedApplications: this.state.preSelectedApplications
    })
  }

  handleCloseModalWithouSave = () => {
    this.setState({
      modalOpen: false,
      preSelectedApplications: this.state.selectedApplications
    })
  }

  handleSaveCohort = () => {
    const { filters, cohortSaveSet } = this.props
    if (filters.data) {
      cohortSaveSet({
        ...filters.data,
        totalUsers: this.state.selectedApplications
      })
    }
  }

  handleGenerateCsv = async () => {
    const { filters } = this.props
    if (filters.data) {
      const { generateCsv } = await generateStringCsv(filters.data)
      const blob = new Blob(['\ufeff', generateCsv], { type: 'text/csv;charset=UTF-8' })
      fileSaver.saveAs(blob, `cohort-${Date.now()}.csv`)
    }
  }

  renderModal = () => {
    const actions = <div className="ModalPreviewCohort__Buttons">
      <Button
        style={{ color: '#B8B5C6' }}
        color="default"
        onClick={this.handleCloseModalWithouSave}>
        {'CANCELAR'}
      </Button>
      <Button
        color="primary"
        disabled={this.state.modalError}
        onClick={this.handleSelectedApplicationsChange}>
        {'SALVAR'}
      </Button>
    </div>

    const children = <>
      <FormInput
        id="total"
        isRequired
        label="Candidatos"
        name="total"
        onInputChange={(value) => { this.handlePreSelectedApplicationsChange(value.value) } }
        placeholder="Candidatos"
        type="number"
        value={this.state.selectedApplications}
      />
      {this.state.modalError && <p> O número não pode ser maior do que o limite de aplicações </p>}
    </>

    return (
      <div className="ModalPreviewCohort" >
        <ModalActions
          modalWidth={'390px'}
          title={'Número de candidatos'}
          description={'Digite o número de candidatos que quer nesse COHORT:'}
          actions={actions}>
          {children}
        </ModalActions>
      </div>
    )
  }

  renderPreview = () => {
    if (!this.props.cohorts.success) return <Spinner />
    return (
      <div className="PreviewCohort">
        <div className="View__Title">
          <Typography
            variant="h2">
            {this.generateCohortName()}
          </Typography>
        </div>
        <div className="View__Subtitle">
          <Typography
            variant="h3">
            {this.props.functions.data?.[0].title}
          </Typography>
        </div>
        <div className="PreviewCohort__Details">
          <div className="PreviewCohort__Details__Labels">
            <Label texts={[`${this.getFilterCounterFromProps()} filtros aplicados`]} icon={<FilterList style={{ color: '#9E9E9E' }} />} onClick={() => {}}/>
            <Label texts={[this.getCompanyFromProps()]} icon={<AccountBalanceSharp style={{ color: '#9E9E9E' }} />} onClick={() => {}}/>
            <Label texts={formatLocationLabel({ states: this.props.filters?.data?.states, cities: this.props.filters?.data?.cities })} icon={<LocationOn style={{ color: '#9E9E9E' }} />} onClick={() => {}}/>
          </div>
          <div className="PreviewCohort__Details__Limit">
            <div className="PreviewCohort__Details__Limit__Total">
              <Create className="PreviewCohort__Details__Limit__Total__Icon" onClick={() => { this.setState({ modalOpen: true }) }}/>
              <Typography
                variant="h1">
                {this.state.selectedApplications || this.props.cohorts.previewData?.total}
              </Typography>
              <Typography
                variant="h2">
                {`/${this.props.cohorts.previewData?.total || 0}`}
              </Typography>
            </div>
            <Typography
              variant="h4">
              {`(restam ${this.getRemainingApplication()} candidatos)`}
            </Typography>
          </div>
        </div>
        <div className="PreviewCohort__Button">
          <Button
            color="primary"
            onClick={this.handleGenerateCsv}>
            {'REALIZAR CONSULTA'}
          </Button>
        </div>
        <div className="PreviewCohort__Button">
          <Button
            color="primary"
            variant='contained'
            onClick={this.handleSaveCohort}>
            {'FINALIZAR COHORT'}
          </Button>
        </div>
      </div>
    )
  }

  render () {
    return (
      <div className="View">
        { this.props.cohorts.error && (
          <div style={{
            display: 'block',
            width: '100%'
          }}>
            <StickyToast show>
              <Typography style={{ color: '#ff4d4d' }}>
                {this.props.cohorts.error.message}
              </Typography>
            </StickyToast>
          </div>
        )}
        {this.state.modalOpen && this.renderModal()}
        <Header
          onClickBack={() => this.props.history.goBack()}
          onClickCancel={() => this.redirectToHome()}
          title="Novo Cohort"/>
        <div className="View__Background">
          <div className="View__Content--centered">
            {this.renderPreview()}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => ({
  cohorts: state.cohorts,
  companies: state.companies,
  filters: state.filters,
  functions: state.functions,
  locations: state.location
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => bindActionCreators(
  {
    ...CohortActions,
    ...CompaniesActions,
    ...FiltersActions,
    ...FunctionActions,
    ...LocationActions
  }, dispatch
)

export default connect(mapStateToProps, mapDispatchToProps)(PreviewCohort)
