import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import getAuthToken from '../../utils/Authentication/getAuthToken'

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_BACKEND_API_URL}/graphql`
})

const authLink = setContext((_, { headers }) => {
  const token = getAuthToken()
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const VojoApollo = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
})

export default VojoApollo
