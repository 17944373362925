import VojoApi from '../VojoApi'

export const getConfiguration = async (companyId: string | undefined) => {
  try {
    const response = await new VojoApi().instance.get(
      `/configuration/company/${companyId}`
    )
    return response
  } catch (errResponse) {
    const error = errResponse.response.data.error
    throw error
  }
}
