import React from 'react'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import CloseIcon from '@material-ui/icons/Close'
import './StatusTag.sass'
interface Props {
  text: string,
  key: string
}

const RenderContent = (text: string) => {
  let content: any
  switch (text) {
    case 'PENDING':
      content = {
        textContent: 'Envio Pendente',
        icon: <AccessTimeIcon fontSize="small"/>
      }
      break
    case 'WAITING':
      content = {
        textContent: 'Aguardando',
        icon: <AccessTimeIcon fontSize="small"/>
      }
      break
    case 'ACCEPTED':
      content = {
        textContent: 'Aceito',
        icon: <CheckCircleOutlineIcon fontSize="small"/>
      }
      break
    case 'ACTIVATED':
      content = {
        textContent: 'Ativado',
        icon: <CheckCircleOutlineIcon fontSize="small"/>
      }
      break
    case 'DENIED':
      content = {
        textContent: 'Negado',
        icon: <CloseIcon fontSize="small"/>
      }
      break
    case 'VALIDATING':
      content = {
        textContent: 'Envio Pendente',
        icon: <AccessTimeIcon fontSize="small"/>
      }
      break
  }
  return (
    <div className={`Tag__${text}`}>
      {content.icon}
      <span>{content.textContent}</span>
    </div>
  )
}

function StatusTag ({
  text
}: Props) {
  return (
    <>
      {RenderContent(text)}
    </>
  )
}

export default StatusTag
