import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#391DDD',
      },
      secondary: {
        main: '#E9E6FF',
      },
    },
    typography: {
        fontFamily: [
          'Rubik',
          'sans-serif',
        ].join(','),
      },
  });

export default theme