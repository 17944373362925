import { createStore, applyMiddleware, compose, Store } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import rootReducer from './rootReducer'
import rootSaga from './rootSaga'
import { CompaniesState } from './companies/types'
import { FunctionState } from './functions/types'
import { CohortState } from './cohorts/types'
import { LocationState } from './location/types'
import { FiltersState } from './filters/types'
import { LoginState } from './login/types'
import { AuthorizationState } from './authorization/types'

export const composeEnhancers = (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

export interface ApplicationState {
  cohorts: CohortState
  companies: CompaniesState
  filters: FiltersState
  functions: FunctionState
  location: LocationState,
  login: LoginState,
  authorization: AuthorizationState
}

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['authorization']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const configureStore = (): Store<ApplicationState> => {
  const sagaMiddleware = createSagaMiddleware()
  let store: Store
  if (process.env.NODE_ENV === 'development' && composeEnhancers) {
    store = createStore(
      persistedReducer,
      undefined,
      compose(
        applyMiddleware(sagaMiddleware),
        composeEnhancers()
      )
    )
  } else {
    store = createStore(
      persistedReducer,
      undefined,
      applyMiddleware(sagaMiddleware)
    )
  }
  sagaMiddleware.run(rootSaga)

  return store
}

export default function configStore () {
  const store = configureStore()
  const persistor = persistStore(store)
  return { persistor, store }
}

export type ConfigureStore = ReturnType<typeof configureStore>
