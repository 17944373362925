import React from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider, Spinner } from '@mindlab-org/component-library'
import { ThemeProvider as ThemeProviderMaterial } from '@material-ui/core/styles'
import { ApolloProvider } from '@apollo/client'
import { PersistGate } from 'redux-persist/integration/react'

import theme from './utils/style/theme'
import configStore from './redux/store'
import AppRouter from './routes/AppRouter'
import VojoApollo from './services/VojoApollo'

export const client = VojoApollo
const { persistor, store } = configStore()

function App () {
  return (
    <ThemeProvider>
      <Provider store={store}>
        <PersistGate loading={<Spinner spinnerArea="30hv"/>} persistor={persistor}>
          <ApolloProvider client={client}>
            <ThemeProviderMaterial theme={theme}>
              <AppRouter/>
            </ThemeProviderMaterial>
          </ApolloProvider>
        </PersistGate>
      </Provider>
    </ThemeProvider>

  )
}

export default App
