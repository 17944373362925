export enum EDegreeCode {
  NDA = 'NDA',
  EFI = 'EFI',
  EFC = 'EFC',
  EMI = 'EMI',
  EMC = 'EMC',
  ETI = 'ETI',
  ETC = 'ETC',
  ESI = 'ESI',
  ESC = 'ESC'
}

export enum EDegreeDescription {
  NDA = 'Sem instrução',
  EFI = 'Ensino fundamental incompleto ou equivalente',
  EFC = 'Ensino fundamental completo ou equivalente',
  EMI = 'Ensino médio incompleto ou equivalente',
  EMC = 'Ensino médio completo ou equivalente',
  ETI = 'Ensino técnico incompleto ou equivalente',
  ETC = 'Ensino técnico completo ou equivalente',
  ESI = 'Ensino superior incompleto',
  ESC = 'Ensino superior completo'
}
