import React from 'react'
import { Typography } from '@material-ui/core'

interface Props {
  id: string
  onClickCard()
  title:string,
  totalApplications:string
}

function FunctionCard ({
  id,
  onClickCard,
  title,
  totalApplications
}: Props) {
  return (
    <div
      className="FunctionCard"
      id={`function-card-${id}`}
      onClick={onClickCard}>
      <div className="FunctionCard__Content">
        <div className="FunctionCard__Block FunctionCard__Block__Left">
          <Typography
            variant="h4">
            {title}
          </Typography>
        </div>
        <div className="FunctionCard__Block FunctionCard__Block__Right">
          <Typography
            variant="h4">
            {totalApplications}
          </Typography>
          <Typography>
            {'candidatos'}
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default FunctionCard
