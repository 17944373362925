import { call, put, takeLatest } from 'redux-saga/effects'

import * as services from './services'
import { LocationTypes } from './types'

function * loadLocations (action) {
  try {
    yield put({ type: LocationTypes.LOCATION_GET_LOAD })
    const data = yield call(services.getLocations, action.payload)
    yield put({ type: LocationTypes.LOCATION_GET_SUCCESS, data: data })
  } catch (err) {
    yield put({ type: LocationTypes.LOCATION_GET_FAILED, err })
  }
}

// This will watch for the actions dispatches
export default function * locationSaga () {
  yield takeLatest(LocationTypes.LOCATION_GET, loadLocations)
}
