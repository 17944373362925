import { action } from 'typesafe-actions'
import { AuthorizationTypes } from './types'

export const authorizationLoad = () => action(AuthorizationTypes.AUTHORIZATION_LOAD)

export const authorizationSet = () => action(AuthorizationTypes.AUTHORIZATION_SET)

export const authorizationUnset = () => action(AuthorizationTypes.AUTHORIZATION_UNSET)

export const authorizationUnsetSuccess = () => action(AuthorizationTypes.AUTHORIZATION_UNSET_SUCCESS)

export const authorizationFailed = () => action(AuthorizationTypes.AUTHORIZATION_FAILED)

export const authorizationSuccess = () => action(AuthorizationTypes.AUTHORIZATION_SUCCESS)
